export default function action() {
  new Action();
}

class Action {
  constructor() {
    this.load();
  }

  private load() {
    this.$form.addEventListener('ajax:before', this.onAjaxBefore);
    this.$form.addEventListener('ajax:error', this.onAjaxError);
    this.$form.addEventListener('ajax:success', this.onAjaxSuccess);
  }

  //
  // events
  //

  private onAjaxBefore = e => {
    this.$error.classList.remove('show');
  };

  private onAjaxError = e => {
    this.$error.classList.add('show');
    this.$error.textContent = e.detail[0].error;
  };

  private onAjaxSuccess = e => {
    this.$form.classList.add('collapse');
    this.$success.classList.add('show');
  };

  //
  // getters
  //

  private get $form() {
    return document.querySelector('form') as HTMLFormElement;
  }

  private get $success() {
    return document.querySelector('.success') as HTMLElement;
  }

  private get $error() {
    return document.querySelector('.error') as HTMLElement;
  }
}
