import anime from 'animejs';
import { AjaxForm } from './ajaxForm';
import { buildFromHtml } from './dom';
import { Memoize } from './memoize';
import { trackNode } from './track';

export function initThreads() {
  document.querySelectorAll('.thread-sheet').forEach($i => {
    const review = new ThreadedContent($i as HTMLDivElement);
  });
}

class ThreadedContent {
  @Memoize() private get $form() {
    return this.$el.querySelector('form') as HTMLFormElement;
  }

  @Memoize() private get $input() {
    return this.$form.querySelector('textarea') as HTMLTextAreaElement;
  }

  @Memoize() private get $cancel() {
    return this.$form.querySelector('.form-button-cancel') as HTMLButtonElement;
  }

  @Memoize() private get $error() {
    return this.$form.querySelector('.form-error') as HTMLDivElement;
  }

  @Memoize() private get $comments() {
    return this.$el.querySelector('.thread-sheet-comments') as HTMLDivElement;
  }

  @Memoize() private get $photos() {
    return this.$el.querySelector('.photo-upload-previews') as HTMLDivElement;
  }

  @Memoize() private get photos() {
    if (!this.$photos) {
      return null;
    }
    return (this.$photos as any).__vue__;
  }

  constructor(private $el: HTMLDivElement) {
    if (!this.$form) {
      return;
    }

    this.$cancel.addEventListener('click', e => {
      e.preventDefault();

      if (window.confirm('Are you sure?')) {
        this.clearForm();
      }
    });

    this.$input.addEventListener('focus', () => this.$form.classList.add('focused'));

    this.$input.addEventListener('blur', () => {
      if (!this.$input.value && !this.$photos) {
        this.$form.classList.remove('focused');
        this.hideError();
      }
    });

    const form = new AjaxForm(this.$form, {
      before: () => this.hideError(),
      error: response => {
        this.showError(response.error_messages[0]);
      },
      success: (response: { html: string; toast?: string }) => {
        this.clearForm();
        this.insertComment(response.html);
        if (response.toast) {
          this.showToast(response.toast);
        }
      },
    });
  }

  private clearForm() {
    this.$input.value = '';
    this.$input.blur();
    this.$form.classList.remove('focused');
    this.photos?.clear();
    this.hideError();
  }

  private insertComment(html: string) {
    const $comment = document.createElement('div');
    $comment.innerHTML = html;
    this.$comments.appendChild($comment);

    // This will fade it in.
    $comment.style.opacity = '0';
    anime({
      targets: $comment,
      opacity: 1,
      duration: 800,
      easing: 'linear',
    });
  }

  private showToast(html: string) {
    const $body = document.querySelector('body') as HTMLBodyElement;
    const $toast = buildFromHtml(html);
    $body.appendChild($toast);
    trackNode($toast);
  }

  private showError(error: string) {
    this.$error.classList.add('show');
    this.$error.textContent = error;
  }

  private hideError() {
    this.$error.classList.remove('show');
  }
}
