export default function action() {
  // We need to set the ratios for the images within the gallery so that they'll
  // size properly.
  document.querySelectorAll('.kg-gallery-image').forEach(($i: HTMLElement) => {
    const $img = $i.querySelector('img');
    if (!$img) {
      return;
    }

    // Ghost puts the image widths and heights as attributes on the image
    // element. We can pull the static ratio from this without having to wait
    // for the image to load.
    const widthAttr = $img.attributes.getNamedItem('width');
    const heightAttr = $img.attributes.getNamedItem('height');
    if (!widthAttr || !heightAttr) {
      return;
    }

    // Attributes are always strings.
    const width = parseInt(widthAttr.value, 10);
    const height = parseInt(heightAttr.value, 10);

    // This will set the flex ratio for this image to size properly against
    // every other image in the gallery container.
    // Example: https://codepen.io/blimpage/pen/obWdgp
    $i.style.flex = String(width / height);
  });

  // handle subscribe forms
  document.querySelectorAll('.subscribe').forEach(($subscribe: HTMLElement) => {
    const $form = $subscribe.querySelector('form') as HTMLFormElement;
    $form.addEventListener('ajax:complete', () => {
      $subscribe.classList.add('complete');
    });
  });
}
