import anime from 'animejs';
import { wait } from './util';

// Defaults
const DEFAULT_DURATION = 400;
const DEFAULT_EASING = 'easeInOutQuad';

interface TransitionOptions {
  delay?: number;
  duration?: number;
  easing?: string;
}
// Animates from one element/snapshot to another element
export default function transition(
  $from: HTMLElement | DOMRect,
  $to: HTMLElement,
  options?: TransitionOptions
): Promise<any> {
  // Ensure that there's a snapshot of the starting element/state
  const from = $from instanceof HTMLElement ? $from.getBoundingClientRect() : $from;
  // Get the ending state
  const to = $to.getBoundingClientRect();
  // Return a Promise that resolves when this animation is complete
  return new Promise(resolve => {
    // Animate the change using anime
    anime
      .timeline({
        targets: $to,
        complete: resolve,
      })
      .add({
        duration: 0,
        easing: 'linear',
        // Position
        translateX: from.left - to.left,
        translateY: from.top - to.top,
        // Size
        scaleX: from.width / to.width,
        scaleY: from.height / to.height,
      })
      .add({
        delay: options?.delay || 0,
        duration: options?.duration || DEFAULT_DURATION,
        easing: options?.easing || DEFAULT_EASING,
        // Position
        translateX: 0,
        translateY: 0,
        // Size
        scaleX: 1,
        scaleY: 1,
      })
      .play();
  });
}

interface TransitionCssOptions {
  delay?: number;
  duration?: number;
  easing?: string;
  from?: any;
  to?: any;
}
// Transitions CSS properties from/to different values
export function transitionCss(
  $targets: HTMLElement | HTMLCollection,
  options: TransitionCssOptions = {}
): Promise<any> {
  // If both from and to are omitted
  if (!options.from && !options.to) {
    throw new Error('options.from or options.to must be specified in transitionCss');
  }
  // Handle number of elements
  let $el;
  if ($targets instanceof HTMLElement) {
    $el = $targets;
  } else if ($targets.length === 0) {
    return wait(options.delay! + (options.duration || DEFAULT_DURATION));
  } else {
    $el = $targets[0];
  }
  // If from is omitted
  if (!options.from) {
    // Use the current styles as from
    options.from = {};
    const style = getComputedStyle($el);
    for (const prop in options.to) {
      options.from[prop] = style[prop];
    }
  } else if (!options.to) {
    // Use the current styles as to
    options.to = {};
    const style = getComputedStyle($el);
    for (const prop in options.from) {
      options.to[prop] = style[prop];
    }
  }
  // Prepare from & to for keyframes
  options.from.easing = 'linear';
  options.from.duration = 0;
  options.to.delay = options.delay || 0;
  options.to.duration = options.duration || DEFAULT_DURATION;
  // Return a Promise that resolves when this animation is complete
  return new Promise(resolve => {
    // Animate the change using anime
    anime
      .timeline({
        targets: $targets,
        complete: resolve,
        easing: options.easing || DEFAULT_EASING,
      })
      .add(options.from)
      .add(options.to)
      .play();
  });
}
