var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (display, image) {
      pug_html = pug_html + "\u003Cdiv class=\"mention-suggestion\"\u003E" + (null == (pug_interp = image) ? "" : pug_interp) + "\u003Cspan class=\"text-truncate\"\u003E" + (null == (pug_interp = display) ? "" : pug_interp) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "display" in locals_for_with ?
        locals_for_with.display :
        typeof display !== 'undefined' ? display : undefined, "image" in locals_for_with ?
        locals_for_with.image :
        typeof image !== 'undefined' ? image : undefined));
    ;;return pug_html;};
module.exports = template;