var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (from, name, tag) {
      pug_html = pug_html + "\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name default\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"l2\"\u003E\u003Cdiv\u003E";
if (tag) {
pug_html = pug_html + (pug.escape(null == (pug_interp = tag) ? "" : pug_interp)) + "\n\n&bull;\n";
}
if (from) {
pug_html = pug_html + "\u003Cspan class=\"from\"\u003Efrom " + (pug.escape(null == (pug_interp = from) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "from" in locals_for_with ?
        locals_for_with.from :
        typeof from !== 'undefined' ? from : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined, "tag" in locals_for_with ?
        locals_for_with.tag :
        typeof tag !== 'undefined' ? tag : undefined));
    ;;return pug_html;};
module.exports = template;