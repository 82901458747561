import anime from 'animejs';
import delegate from 'delegate';
import MicroModal from 'micromodal';
import { gridBreakpoints } from '../lib/env';
import { fcMetadata } from '../lib/fc_metadata';
import { highlight } from '../lib/highlighter';
import { track } from '../lib/track';
import transition from '../lib/transition';
import { mobile } from '../lib/userAgent';

export default function action() {
  // If this is a phone (an actual touch device, not just an xs window)
  const isMobileGuide = mobile && screen.width < gridBreakpoints.sm;
  if (isMobileGuide) {
    document.documentElement.classList.add('mobile-guide');
    new GuideItemsTabView(document.querySelector('.item-list'));
    track('View Mobile Web Guide');
  }

  const modalAttr = 'modal';
  delegate(`[data-${modalAttr}]`, 'click', (e: MouseEvent) => {
    const $target = (e.target as HTMLElement).closest(`[data-${modalAttr}]`) as HTMLElement;
    MicroModal.show(`${$target.dataset[modalAttr]!}-modal`, { disableFocus: true });
  });

  delegate('.cta .btn', 'ajax:success', (e: CustomEvent) => handleSuccess(e));

  // If the user is returning from auth
  const selector = '.flash-guide-item';
  const $item = document.querySelector(selector);
  if ($item) {
    const $btn = document.querySelector(
      `${selector} .${isMobileGuide ? 'mobile-' : ''}cta .btn`,
    ) as HTMLElement;
    updateCTA($btn, $btn.classList.contains('promo') ? 'Copy Promo Code' : '');
    highlight({ selector });
  }
}

function handleSuccess(e: CustomEvent) {
  const [data] = e.detail;

  // handle redirect (not logged in)
  if (data.redirect) {
    window.location.href = data.redirect;
    return;
  }

  // switch to $cta.success
  if (data.success) {
    const $target = e.target as HTMLElement;
    const snapshot = $target.getBoundingClientRect();
    updateCTA($target);
    transition(snapshot, $target);
  }
}

// Updates a .cta to the success state
function updateCTA($target: HTMLElement, label = '') {
  if ($target.classList.contains('done')) {
    return;
  }

  const $cta = $target.closest('.cta, .mobile-cta') as HTMLElement;
  // Avoid elements moving around on the page after the initial .giveaway-help
  // element is hidden (only necessary for xs/sm devices)
  if ($target.classList.contains('prize') && window.innerWidth < gridBreakpoints.md) {
    const $help = document.querySelector('.cta > .giveaway-help') as HTMLElement;
    $cta.style.marginBottom = `${$help.getBoundingClientRect().height}px`;
  }
  $cta.classList.add('success');

  if (label) {
    $target.textContent = label;
    return;
  }

  $target.classList.add('done');
  if ($target.classList.contains('business')) {
    $target.textContent = 'Added to bookmarks';
  } else if ($target.classList.contains('promo')) {
    $target.textContent = 'Promo code copied';
  } else if ($target.classList.contains('prize')) {
    $target.textContent = 'Entered in giveaway';
  } else {
    $target.textContent = 'Added to wishlist';
  }
}

// Handles gestures & tabs for guide items on mobile devices
class GuideItemsTabView {
  private $container: HTMLDivElement;
  private $heads: HTMLDivElement;
  private $bodies: HTMLDivElement;
  private $feet: HTMLDivElement;
  private $previousArrow: HTMLDivElement;
  private $nextArrow: HTMLDivElement;
  private activeIndex = 0;
  private tabCount = 0;

  private get $activeHead() {
    return this.$heads.children[this.activeIndex] as HTMLDivElement;
  }
  private get $activeBody() {
    return this.$bodies.children[this.activeIndex] as HTMLDivElement;
  }

  // Gesture properties
  private isHeld = false; // Whether a (possible) gesture is currently in progress
  private hasMoved = false; // Whether the pointer has moved enough to determine intent
  private pointerXStart = 0;
  private pointerYStart = 0;
  private pointerX = 0;
  private pointerY = 0;
  private translateStart = 0; // The translateX in px when the gesture started

  private get leftValid() {
    return !!this.activeIndex;
  }
  private get rightValid() {
    return this.activeIndex + 1 < this.tabCount;
  }

  constructor($container) {
    this.$container = $container;
    $container.classList.add('tab-view');
    if (!fcMetadata.logged_in) {
      $container.classList.add('anonymous-user');
    }
    $container.appendChild(this.createElement());
    this.tabCount = this.$bodies.children.length;
    this.selectTab(0);
  }

  createElement() {
    this.$heads = document.createElement('div');
    this.$bodies = document.createElement('div');
    this.$feet = document.createElement('div');
    const { $heads, $bodies, $feet } = this;

    $heads.className = 'heads';
    $heads.addEventListener('click', ev => this.handleHeadClick(ev.target as HTMLElement));

    $bodies.className = 'bodies';
    window.addEventListener('touchstart', ev => this.handleTouchStart(ev), { passive: false });
    window.addEventListener('touchmove', ev => this.handleTouchMove(ev), { passive: false });
    window.addEventListener('touchend', () => this.handleTouchEnd());
    window.addEventListener('touchcancel', () => this.handleTouchEnd());

    $feet.className = 'feet';

    // Move the intro section into this
    {
      const $head = document.createElement('div');
      $head.className = 'head';
      $head.textContent = 'Introduction';
      $heads.appendChild($head);
      const $body = document.createElement('div');
      $body.className = 'tab-body introduction-slide';
      $body.style.setProperty('--index', '0');
      const $hero = document.querySelector('.join-hero');
      if ($hero) {
        $body.appendChild($hero);
      }
      $body.appendChild(document.querySelector('.top-banner') as HTMLElement);
      const $top = document.querySelector('.mobile-top') as HTMLElement;
      $top.querySelector('.swipe-hint')?.addEventListener('click', this.handleArrowClick.bind(this, 1));
      // Ensure that the author blurb is expanded
      $top.classList.add('toggle');
      $body.appendChild($top);
      $bodies.appendChild($body);
    }

    // Restructure the guide items
    for (let $child; ($child = this.$container.firstElementChild);) {
      const $name = $child.querySelector('.tab-name') as HTMLElement | null;
      if (!$name) {
        $child.remove();
        continue;
      }
      const $head = document.createElement('div');
      $head.className = 'head';
      $head.textContent = $name.textContent;
      $heads.appendChild($head);
      $child.classList.add('tab-body');
      const index = $bodies.children.length;
      $child.style.setProperty('--index', index);
      $child
        .querySelector('.mobile-cta .btn')
        ?.addEventListener('ajax:success', (e: CustomEvent) => handleSuccess(e));
      const $foot = $child.querySelector('.slide-footer');
      if ($foot) {
        $foot.style.left = `${100 * index}%`;
        $feet.appendChild($foot);
      }
      $bodies.appendChild($child);
    }

    const $frag = document.createDocumentFragment();
    $frag.appendChild($heads);
    $frag.appendChild($bodies);
    $frag.appendChild($feet);
    $frag.appendChild((this.$previousArrow = this.createArrowBtn('previous', -1)));
    $frag.appendChild((this.$nextArrow = this.createArrowBtn('next', 1)));
    const $hint = document.createElement('div');
    $hint.className = 'continue-hint';
    $hint.textContent = 'See the guide';
    this.$nextArrow.appendChild($hint);
    return $frag;
  }

  createArrowBtn(klass: string, offset: number) {
    const $btn = document.createElement('div');
    $btn.className = `arrow-btn ${klass}`;
    $btn.addEventListener('click', this.handleArrowClick.bind(this, offset));
    return $btn;
  }

  handleHeadClick($target: HTMLElement) {
    const $head = $target.closest('.head');
    if (!$head) {
      return;
    }
    const index = Array.prototype.indexOf.call(this.$heads.children, $head);
    this.selectTab(index, 'head');
  }

  handleArrowClick(offset: number) {
    if (!this.$bodies.children[this.activeIndex + offset]) {
      return;
    }
    this.selectTab(this.activeIndex + offset, 'arrow');
    this.scrollToActiveHead();
  }

  handleTouchStart(ev: TouchEvent) {
    if (this.isHeld || ev.touches.length > 1 || !this.$bodies.contains(ev.target as HTMLElement)) {
      return;
    }
    this.pointerX = this.pointerXStart = ev.touches[0].clientX;
    this.pointerY = this.pointerYStart = ev.touches[0].clientY;
    this.isHeld = true;
    this.requestFrame();
  }

  handleTouchMove(ev: TouchEvent) {
    if (!this.isHeld) {
      return;
    }
    this.pointerX = ev.touches[0].clientX;
    this.pointerY = ev.touches[0].clientY;
    if (this.hasMoved) {
      ev.preventDefault();
    }
  }

  handleTouchEnd() {
    if (!this.isHeld) {
      return;
    }
    this.isHeld = false;
    if (!this.hasMoved) {
      return;
    }
    this.hasMoved = false;
    this.$bodies.style.transition = this.$feet.style.transition = '';
    const deltaX = this.pointerX - this.pointerXStart;
    const threshold = 48;
    let index = this.activeIndex;
    if (deltaX > threshold && this.leftValid) {
      index--;
    } else if (deltaX < -threshold && this.rightValid) {
      index++;
    } else {
      return this.selectTab(index);
    }
    this.selectTab(index, 'swipe');
    this.scrollToActiveHead();
  }

  requestFrame() {
    requestAnimationFrame(this.handleFrame.bind(this));
  }

  handleFrame() {
    if (!this.isHeld) {
      return;
    }
    this.requestFrame();

    // Determine user intent before doing anything
    if (!this.hasMoved) {
      const d = Math.sqrt(
        Math.pow(this.pointerX - this.pointerXStart, 2) +
        Math.pow(this.pointerY - this.pointerYStart, 2)
      );
      if (d < 12) {
        return;
      }
      // If this gesture is mostly vertical, don't interrupt it
      if (
        Math.abs(this.pointerY - this.pointerYStart) > Math.abs(this.pointerX - this.pointerXStart)
      ) {
        return this.handleTouchEnd();
      }
      this.hasMoved = true;
      this.translateStart = -window.innerWidth * this.activeIndex;
      this.$bodies.style.transition = this.$feet.style.transition = 'none';
    }

    const deltaX = this.pointerX - this.pointerXStart;
    let ratio = 1;
    if ((deltaX > 0 && !this.leftValid) || (deltaX < 0 && !this.rightValid)) {
      ratio = 0.25;
    }
    this.$bodies.style.transform = this.$feet.style.transform = `translateX(${this.translateStart + ratio * deltaX}px)`;
  }

  selectTab(index: number, method?: string) {
    this.$heads.querySelector('.active')?.classList.remove('active');
    if (this.activeIndex !== index) {
      anime({
        targets: document.documentElement,
        scrollTop: 0,
        duration: 250,
        easing: 'easeInOutQuad',
      });
    }
    this.activeIndex = index;
    this.$activeHead.classList.add('active');
    this.$bodies.style.transform = this.$feet.style.transform = `translateX(${-100 * index}%)`;
    this.updateArrowEnabled(this.$previousArrow, this.leftValid);
    this.updateArrowEnabled(this.$nextArrow, this.rightValid);
    this.$container.dataset.activeTopicType = this.$activeBody.dataset.topicType || '';
    this.$container.dataset.activeSlideType = this.$activeBody.className
      .split(' ')
      .find(x => x.endsWith('-slide'))
      ?.replace('-slide', '') || 'item';
    if (index) {
      this.$nextArrow.querySelector('.continue-hint')?.remove();
    }
    if (method) {
      track('Select Mobile Guide Tab', { method });
    }
  }

  updateArrowEnabled($element: HTMLElement, enabled: boolean) {
    if (enabled) {
      $element.classList.remove('disabled');
    } else {
      $element.classList.add('disabled');
    }
  }

  scrollToActiveHead() {
    anime({
      targets: this.$heads,
      scrollLeft: this.$heads.scrollLeft + this.$activeHead.getBoundingClientRect().left,
      duration: 250,
      easing: 'easeInOutQuad',
    });
  }
}
