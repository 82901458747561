import { getBreakpoint } from '../lib/env';

export default function action() {
  const $banner = document.querySelector('.banner-carousel') as HTMLElement;
  new BannerCarousel($banner);
}

const AUTO_PROGRESS_INTERVAL = 5000;

class BannerCarousel {
  public $banner: HTMLElement;
  // The index of the visible slide
  public index = 0;
  // An identifier for the interval to automatically switch between slides
  // (stored so that it can be cancelled if the user changes slides directly)
  private interval = 0;
  // The number of slides in this carousel
  public slideCount = 0;

  get $slides() {
    return this.$banner.querySelector('.slides') as HTMLElement;
  }

  get $active() {
    return this.$slides.children[this.index] as HTMLElement;
  }

  get $pagination() {
    return this.$banner.querySelector('.pagination') as HTMLElement;
  }

  get transitionDuration() {
    if (getBreakpoint() === 'xs') {
      return 500;
    }
    return 750;
  }

  // Gesture properties
  private isHeld = false; // Whether a (possible) gesture is currently in progress
  private hasMoved = false; // Whether the pointer has moved enough to determine intent
  private pointerXStart = 0;
  private pointerYStart = 0;
  private pointerX = 0;
  private pointerY = 0;
  private translateStart = 0; // The translateX in px when the gesture started

  private get leftValid() {
    return !!this.index;
  }
  private get rightValid() {
    return this.index + 1 < this.slideCount;
  }

  constructor($banner: HTMLElement) {
    this.$banner = $banner;
    this.index = 0;
    this.slideCount = this.$slides.children.length;
    if (!this.slideCount) {
      throw 'BannerCarousel must have at least one slide';
    }
    this.createPagination();
    this.updatePagination();
    this.interval = setInterval(
      this.showNext.bind(this),
      AUTO_PROGRESS_INTERVAL + this.transitionDuration,
    ) as any;

    window.addEventListener('touchstart', ev => this.handleTouchStart(ev), { passive: false });
    window.addEventListener('touchmove', ev => this.handleTouchMove(ev), { passive: false });
    window.addEventListener('touchend', () => this.handleTouchEnd());
    window.addEventListener('touchcancel', () => this.handleTouchEnd());
  }

  createPagination() {
    const $fragment = document.createDocumentFragment();
    for (let i = 0; i < this.slideCount; ++i) {
      const $page = document.createElement('div');
      $page.className = 'page';
      $page.addEventListener('click', this.show.bind(this, i, true));
      $fragment.appendChild($page);
    }
    this.$pagination.appendChild($fragment);
  }

  updatePagination() {
    const { $pagination } = this;
    $pagination.querySelector('.active')?.classList.remove('active');
    $pagination.children[this.index].classList.add('active');
  }

  show(index: number) {
    this.index = index;
    this.$slides.style.transform = `translateX(${this.index * -100}%)`;
    this.updatePagination();
  }

  showPrev() {
    this.show(this.index ? this.index - 1 : this.slideCount - 1);
  }

  showNext() {
    this.show((this.index + 1) % this.slideCount);
  }

  clearInterval() {
    if (!this.interval) {
      return;
    }
    clearInterval(this.interval);
    this.interval = 0;
  }

  handleTouchStart(ev: TouchEvent) {
    if (this.isHeld || ev.touches.length > 1 || !this.$slides.contains(ev.target as HTMLElement)) {
      return;
    }
    this.pointerX = this.pointerXStart = ev.touches[0].clientX;
    this.pointerY = this.pointerYStart = ev.touches[0].clientY;
    this.isHeld = true;
    this.requestFrame();
  }

  handleTouchMove(ev: TouchEvent) {
    if (!this.isHeld) {
      return;
    }
    this.pointerX = ev.touches[0].clientX;
    this.pointerY = ev.touches[0].clientY;
    if (this.hasMoved) {
      ev.preventDefault();
    }
  }

  handleTouchEnd() {
    if (!this.isHeld) {
      return;
    }
    this.isHeld = false;
    if (!this.hasMoved) {
      return;
    }
    this.hasMoved = false;
    this.$slides.style.transition = '';
    const deltaX = this.pointerX - this.pointerXStart;
    const threshold = 48;
    if (deltaX > threshold && this.leftValid) {
      this.index--;
    } else if (deltaX < -threshold && this.rightValid) {
      this.index++;
    }
    this.show(this.index);
  }

  requestFrame() {
    requestAnimationFrame(this.handleFrame.bind(this));
  }

  handleFrame() {
    if (!this.isHeld) {
      return;
    }
    this.requestFrame();

    // Determine user intent before doing anything
    if (!this.hasMoved) {
      const d = Math.sqrt(
        Math.pow(this.pointerX - this.pointerXStart, 2) +
          Math.pow(this.pointerY - this.pointerYStart, 2),
      );
      if (d < 12) {
        return;
      }
      // If this gesture is mostly vertical, don't interrupt it
      if (
        Math.abs(this.pointerY - this.pointerYStart) > Math.abs(this.pointerX - this.pointerXStart)
      ) {
        return this.handleTouchEnd();
      }
      this.hasMoved = true;
      this.translateStart = -this.$banner.getBoundingClientRect().width * this.index;
      this.$slides.style.transition = 'none';
      this.clearInterval();
    }

    const deltaX = this.pointerX - this.pointerXStart;
    let ratio = 1;
    if ((deltaX > 0 && !this.leftValid) || (deltaX < 0 && !this.rightValid)) {
      ratio = 0.1;
    }
    this.$slides.style.transform = `translateX(${this.translateStart + ratio * deltaX}px)`;
  }
}
