/**
 * Turn an object & lodash template function into an HTMLElement.
 */
export function build(o: Record<string, any>, fn: (o: Record<string, any>) => string): HTMLElement {
  const $template = document.createElement('template');
  $template.innerHTML = fn(o).trim();
  return $template.content.firstElementChild as HTMLElement;
}

/**
 * Returns DOM element created from a string of HTML.
 */
export function buildFromHtml(html: string) {
  const $el = document.createElement('div');
  $el.innerHTML = html.trim();

  return $el.firstChild as HTMLElement;
}

const CSS_DISTANCE = [
  'borderRadius',
  'borderWidth',
  'bottom',
  'height',
  'left',
  'right',
  'top',
  'width',
];
const CSS_NUMBER = ['opacity'];
const CSS_STRING = ['display', 'position', 'visibility'];

export function css($el: HTMLElement, style: Style) {
  for (const key of CSS_DISTANCE) {
    if (key in style) {
      const value = style[key];
      $el.style[key] = typeof value === 'number' ? `${value}px` : value;
    }
  }
  for (const key of CSS_NUMBER) {
    if (key in style) {
      const value = style[key];
      $el.style[key] = typeof value === 'number' ? value.toString() : value;
    }
  }
  for (const key of CSS_STRING) {
    if (key in style) {
      $el.style[key] = style[key];
    }
  }
}

interface Style {
  // CSS_DISTANCE
  borderRadius?: number | string;
  borderWidth?: number | string;
  bottom?: number | string;
  height?: number | string;
  left?: number | string;
  right?: number | string;
  top?: number | string;
  width?: number | string;

  // CSS_NUMBER
  opacity?: number | string;

  // CSS_STRING
  display?: string;
  position?: string;
  visibility?: string;
}
