interface FcMetadata {
  base_url: string;
  logged_in: boolean;
  page: { [k: string]: any };
  user_city: {
    name: string;
    ghost_town: boolean;
    loc: number[];
  };
}

export const fcMetadata = (window as any).fcMetadata as FcMetadata;
