export const googleMapsApiKey = 'AIzaSyD_f_mexPCje-j0rY8C464f309N0emwd8U';

// copied from env.scss
export const fcColors = {
  teal: '#1f9e9e',
  green: '#63b800',
  yellow: '#d6de40',
  blue: '#30708f',
  purple: '#b0428a',
  orange: '#d15c30',
  red: '#cf4255',
  dark: '#45423b',
};

// copied from bootstrap _variables.scss
export const gridBreakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

// copied from bootstrap _variables.scss
export const containerMaxWidths = {
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
};

export function getBreakpoint(): string {
  const width = window.innerWidth;
  for (const b of ['xl', 'lg', 'md', 'sm']) {
    if (width >= gridBreakpoints[b]) {
      return b;
    }
  }
  return 'xs';
}
