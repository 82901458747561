import delegate from 'delegate';

export default function init() {
  delegate('[data-href]', 'click', e => {
    const $target = e.target as HTMLElement;
    const $dataHref = $target.closest('[data-href]') as HTMLElement;

    // Bail if the user clicked an anchor tag WITHIN the data-href, because the
    // anchor tag should take precedence.
    if ($dataHref.contains($target.closest('a[href]'))) {
      return;
    }

    // follow the data-href
    if ($dataHref.dataset.href) {
      if ($dataHref.dataset.target) {
        window.open($dataHref.dataset.href, $dataHref.dataset.target);
      } else {
        window.location.href = $dataHref.dataset.href;
      }
    }

    e.preventDefault();
  });
}
