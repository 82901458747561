import { highlight } from '../lib/highlighter';
import keyCodes from '../lib/keyCodes';
import { track } from '../lib/track';
import { setIsOnboarding } from '../onboard';

export default function action() {
  // When enter is pressed in the email input, shows the email details section of the form
  const $emailInput = document.querySelector('.user_email input') as HTMLInputElement;
  $emailInput?.addEventListener('keypress', e => {
    if (e.keyCode === keyCodes.enter) {
      e.preventDefault();
      showEmailDetails();
    }
  });
  // Exposes the rest of the form after the user has selected the email option.
  // (During OAuth flows, this is shown automatically due to a condition in the HAML)
  const showEmailDetails = () => {
    // If the details are already visible, stop here
    if (document.body.classList.contains('email-form-details')) {
      return;
    }
    // Track that this user is creating their account using the email option
    track('View Create Account Email');
    const $details = document.querySelector('.form-details') as HTMLDivElement;
    // Capture some info about positions to animate changes after
    const inputRect = $emailInput.getBoundingClientRect();
    const btnRect = $continueBtn.getBoundingClientRect();
    // Hide the "Continue" button & oauth
    document.body.classList.add('email-form-details');
    // Animate the change
    const duration = 250;
    const $email = $emailInput.parentElement as HTMLDivElement;
    const $btn = document.querySelector('input[type=submit]') as HTMLInputElement;
    $details.style.visibility = 'hidden';
    $email.style.zIndex = '4';
    $email.style.transform = `translateY(${inputRect.top - $email.getBoundingClientRect().top}px)`;
    $btn.style.transform = `translateY(${btnRect.top - $btn.getBoundingClientRect().top}px)`;
    // Wait for the next frame to begin transition
    requestAnimationFrame(() => {
      // Transition to the actual position
      $email.style.transition = `transform ${duration}ms`;
      $btn.style.transition = `transform ${duration}ms`;
      $email.style.transform = '';
      $btn.style.transform = '';
    });
    // After the transition ends
    setTimeout(() => {
      // Reset inline styles
      $details.style.visibility = '';
      $email.style.transition = '';
      $btn.style.transition = '';
      // Focus on the "first name" input in the form (after email)
      const $firstName = document.querySelector('[name="user[first_name]"]') as HTMLInputElement;
      $firstName?.focus();
      // Animate in form rows
      let duration = 100;
      for (let x = 0, y = $details.children.length; x < y; ++x) {
        const $child = $details.children[x] as HTMLElement;
        // Don't animate in the button, it's already visible
        if ($child === $btn) {
          continue;
        }
        // Animate in each row, staggered
        $child.style.animation = `form-details-in ${duration}ms`;
        duration += 75;
      }
    }, duration + 16);
  };
  // When the "Continue" button under the email input is clicked, shows the email details
  // section of the form
  const $continueBtn = document.querySelector('.email-continue-button') as HTMLButtonElement;
  $continueBtn?.addEventListener('click', showEmailDetails);
  // Exposes the minimized content and scrolls it into view
  const expandMinimized = () => {
    track('Expand Invite Form');
    const $container = document.querySelector('#create-account-container')!;
    $container.classList.remove('minimized');
    highlight({ selector: '.form-column' });
  };
  // When the "Continue" button in the minimized variant is clicked (used for mobile invites)
  const $expandBtn = document.querySelector('.btn-expand') as HTMLButtonElement;
  $expandBtn?.addEventListener('click', expandMinimized);
  // Find the form element
  const $form = document.querySelector('form') as HTMLFormElement;
  // When the form is submitted, indicate that the user is onboarding
  $form.addEventListener('submit', setIsOnboarding);
}
