import delegate from 'delegate';
import MicroModal from 'micromodal';

export default function action() {
  const modalAttr = 'modal';
  delegate(`[data-${modalAttr}]`, 'click', (e: MouseEvent) => {
    const $target = (e.target as HTMLElement).closest(`[data-${modalAttr}]`) as HTMLElement;
    MicroModal.show(`${$target.dataset[modalAttr]}-modal`, { disableFocus: true });
  });
}
