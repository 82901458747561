import anime from 'animejs';
import { track } from '../lib/track';

export default function init() {
  const $hamburger = document.querySelector('.hamburger') as HTMLElement;
  if ($hamburger) {
    const $cover = document.querySelector('.hamburger-cover') as HTMLElement;
    $hamburger.addEventListener('click', () => toggleHamburger());
    $cover.addEventListener('click', () => toggleHamburger());
  }
}

/**
 * @param force You can pass in true/false to force it showing or hiding instead
 * of doing the default toggle behavior, similar to classList.toggle()
 */
export function toggleHamburger(force?: boolean) {
  const $icon = document.querySelector('.hamburger') as HTMLElement;
  const $cover = document.querySelector('.hamburger-cover') as HTMLElement;
  const $menu = document.querySelector('.hamburger-menu') as HTMLElement;

  const show = force !== undefined ? force : !$icon.classList.contains('active');
  $icon.classList.toggle('active', show);

  //
  // animation
  //
  // note: I tried hard to use timeline.reverse() instead of manual reversing
  // here, but I wasn't able to make it work.
  //
  // note #2: 18rem comes from navbar.scss
  //

  const options: any = { easing: 'easeOutQuad', duration: 300, begin: null, complete: null };
  const cover = { targets: $cover, opacity: [0, 0.7] };
  const menu = { targets: $menu, left: ['-18rem', 0] };

  if (show) {
    track('Show Menu');
    options.begin = () => document.documentElement.classList.toggle('hamburger-visible', show);
  } else {
    options.complete = () => document.documentElement.classList.toggle('hamburger-visible', show);
    cover.opacity = cover.opacity.reverse();
    menu.left = menu.left.reverse();
  }

  // go!
  const timeline = anime.timeline(options);
  // The typings are wrong here. It doesn't think we can add the offset.
  (timeline as any).add(cover, 0);
  (timeline as any).add(menu, 0);
}
