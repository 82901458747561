import anime from 'animejs';
import delegate from 'delegate';
import { buildFromHtml, css } from './dom';
import { get } from './rest';
import Routes, { ApiUsersWhoToFollowNextResponse } from './routes';

//
// handle ajax FOLLOW/UNFOLLOW buttons
//

export default function action() {
  // .follow handler
  delegate('.follow', 'ajax:success', onAjaxSuccess);

  // on wtf close, replace
  delegate('.who-to-follow .close-box, .who-to-follow-sheets .close-box', 'click', e => {
    const $follow = e.target as HTMLElement;
    replaceWhoToFollow($follow, true);
  });
}

function onAjaxSuccess(e: CustomEvent) {
  const [data, _status, _xhr]: [any, string, XMLHttpRequest] = e.detail;

  // handle redirect (not logged in)
  if (data.redirect) {
    window.location.href = data.redirect;
    return;
  }

  // success!
  const $follow = (e.target as HTMLElement).closest('.follow') as HTMLElement;
  $follow.classList.toggle('following', data.following);

  if (data.following) {
    replaceWhoToFollow($follow);
  }
}

// component so that we can update with a new user to follow. When following, we
// want to see if it was done in the "who to follow"
async function replaceWhoToFollow($follow: HTMLElement, remember = false) {
  const $users = $follow.closest('.users') as HTMLElement;
  const $item = $follow.closest('.user-item') as HTMLElement;
  if (!$users || !$item) {
    // We're not in wtf
    return;
  }

  // which template - regular, or sheets?
  let template: string | undefined;
  const $wtf = $users.closest('.who-to-follow');
  if (!$wtf) {
    template = 'sheets';
  }

  const skipId = remember ? $item.dataset.userId! : '';

  // Hide the controls so it doesn't flash the "unfollow" button.
  css($item.querySelector('.follow') as HTMLElement, { visibility: 'hidden' });

  // Get the current set of users so that we can tell the API not to return any
  // of these back to us (avoid duplicates).
  const blacklist: number[] = [];
  $users
    .querySelectorAll<HTMLElement>('.user-item')
    .forEach(i => blacklist.push(parseInt(i.dataset.userId!, 10)));

  const response = await get<ApiUsersWhoToFollowNextResponse>(
    Routes.usersWhoToFollowNext(blacklist, skipId, template),
  );

  const $el = buildFromHtml(response.html);

  const animProps: anime.AnimeParams = {
    targets: null,
    duration: 200,
    easing: 'linear',
  };

  // Fade out/in animation to replace the followed item.
  await anime({
    ...animProps,
    targets: $item,
    opacity: 0,
  }).finished;

  $el.style.opacity = '0';
  $item.parentNode!.insertBefore($el, null);
  $item.remove();

  anime({
    ...animProps,
    targets: $el,
    opacity: 1,
  });
}
