export default function action() {
  const $notes = document.querySelector('#object_notes') as HTMLInputElement;
  const $previews = document.querySelectorAll('.sheet-caution .notes');
  function updatePreviews() {
    $previews.forEach($i => ($i.textContent = $notes.value));
  }

  // update right now
  updatePreviews();

  // Notes is only available if they have enough karma to post.
  if ($notes) {
    // update when notes change
    $notes.addEventListener('input', updatePreviews);
    // update after tribute
    $notes.addEventListener('tribute-replaced', updatePreviews);
  }
}
