//
// Helpers for developers. Example - createAccount, which can be called from a
// bookmarklet.
//

import { randomAlpha, sample } from './util';

export default function init() {
  (window as any).dev = {
    createAccount,
  };
}

const createAccount = () => {
  const id = randomAlpha(3);
  const zip = sample(['98102', '98103', '98105', '98107', '98199']);

  const $form = document.querySelector('form.new_user') as HTMLFormElement;
  const fields = {
    email: `john${id}@example.com`,
    password: 'password',
    first_name: `John`,
    last_name: `Smith${id}`,
    zipcode: zip,
    screen_name: `john_smith_${id}`,
  };
  for (const [key, value] of Object.entries(fields)) {
    const $el = $form.querySelector(`#user_${key}`) as HTMLInputElement;
    $el.value = value;
    $el.classList.remove('placeholder-shown');
  }
};
