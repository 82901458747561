import delegate from 'delegate';
import { AjaxForm } from '../lib/ajaxForm';
import Routes from '../lib/routes';
import { track } from '../lib/track';

export default function action() {
  initializeFormComponent();

  // note - there can be more than one selector
  delegate('.locale', 'change', (e: Event) => {
    const $locale = e.target as HTMLSelectElement;
    const locale = $locale.value;
    window.location.href = Routes.url(window.location.href, { locale });
  });
}

function initializeFormComponent() {
  const $form = document.querySelector('#new_sites_sign_up') as HTMLFormElement;
  const $error = $form.querySelector('.form-error') as HTMLDivElement;

  new AjaxForm($form, {
    before: () => {
      $error.classList.remove('show');
      track('Fresh Sites Sign Up');
    },
    error: response => {
      $error.classList.add('show');
      $error.textContent = response.error_messages[0];
      track('Invalid Fresh Sites Sign Up');
    },
    success: (response: { success: boolean, message: string }) => {
      if (response.success) {
        const alert = document.createElement('div');
        alert.classList.add('alert');
        alert.classList.add('alert-success');
        alert.innerText = response.message;
        $form.parentElement?.replaceWith(alert);
      }
    },
  });
}
