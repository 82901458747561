interface FormErrors {
  error_messages: string[];
}

type BeforeCallback = () => void;
type ErrorCallback = (response: FormErrors) => void;
type SuccessCallback = (response: any) => void;

interface Options {
  before?: BeforeCallback;
  error?: ErrorCallback;
  success?: SuccessCallback;
}

// data, status, xhr
type FormEvent = CustomEvent<[any, string, XMLHttpRequest]>;

export class AjaxForm {
  constructor(public $form: HTMLFormElement, private options: Options) {
    $form.addEventListener('ajax:before', () => {
      if (options.before) {
        options.before();
      }
    });

    $form.addEventListener('ajax:success', (e: FormEvent) => {
      this.handleResponse(e, options.success);
    });

    $form.addEventListener('ajax:error', (e: FormEvent) => {
      this.handleResponse(e, options.error);
    });
  }

  private handleResponse(e: FormEvent, cb?: ErrorCallback | SuccessCallback) {
    if (cb) {
      const response = e.detail[0];
      cb(response);
    }
  }
}
