import delegate from 'delegate';
import tagAutocomplete from '../lib/autocomplete/tagAutocomplete';
import initReviews from './_reviews';

export default function action() {
  // tag autocomplete for new reviews
  const $tagInput = document.querySelector('#object_tag_name') as HTMLInputElement;
  if ($tagInput) {
    tagAutocomplete($tagInput);
  }

  // increment following count
  delegate('.who-to-follow', 'ajax:success', () => {
    // increment text
    const $nfollowing = document.querySelector('.nfollowing') as HTMLElement;
    $nfollowing.innerText = (parseInt($nfollowing.innerText, 10) + 1).toString();

    // pop animation
    const $li = $nfollowing.closest('li') as HTMLElement;
    $li.classList.add('anim-pop');
    setTimeout(() => $li.classList.remove('anim-pop'), 500);
  });

  initReviews();
}
