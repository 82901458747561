import { format } from 'timeago.js';

// Client-side conversion of timestamp to "n xxx ago" format,
// similar to time_ago_in_words in rails.
//
// Set data-timeago to a timestamp and the content of the tag
// will be replaced with the formatted string.
//
// This is useful when rendering cached or otherwise stale content.

export default function init() {
  document.querySelectorAll('[data-timeago]').forEach(($el: HTMLElement) => {
    const timestamp = $el.dataset.timeago;
    if (!timestamp) {
      return;
    }

    const formatted = format(timestamp);
    if (!formatted) {
      return;
    }

    // Sanity check. Nothing in the future
    if (formatted.startsWith('in')) {
      console.log(`bad timestamp ${timestamp}`);
      return;
    }

    $el.textContent = formatted;
  });
}
