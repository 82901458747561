import tippy from 'tippy.js';
import highlightHash from '../lib/highlightHash';
import { initThreads } from '../lib/threads';
import { getPageTrackProperties, track } from '../lib/track';
import { initVoting } from '../lib/voting';

export default function action() {
  tippy('.claimed', {
    appendTo: () => document.body,
    theme: 'light-border',
    animation: 'shift-away',
    delay: [750, 0],
    content: '<div class="claim-tippy">This business has been claimed by the owner or a company representative <a href="/business" target="_blank">Learn more</a></div>',
    arrow: true,
    interactive: true,
    placement: 'bottom',
    allowHTML: true,
    maxWidth: 200,
  });

  initThreads();
  initVoting();
  highlightHash();

  const bizProperties = getPageTrackProperties();

  // track external link usage
  const $websiteLink = document.querySelector('.biz-website');
  $websiteLink?.addEventListener('click', () => {
    track('Click Biz Website', bizProperties);
  });
  const $mapLink = document.querySelector('.biz-map');
  $mapLink?.addEventListener('click', () => {
    track('Click Biz Map', bizProperties);
  });
  const $reviewsLinks = document.querySelectorAll('.biz-reviews');
  $reviewsLinks.forEach(reviewsLinkElement => {
    reviewsLinkElement.addEventListener('click', (e: Event) => {
      const $target = e.target as HTMLElement;
      const $name = $target.closest('.name') as HTMLElement;
      const properties = $name ? { type: $name.innerText } : {};
      track('Click Biz Reviews', { ...bizProperties, ...properties });
    });
  });
}
