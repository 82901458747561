import isBackButtonNav from './isBackButtonNav';

const TTL = 3 * 60 * 1000; // 3 minutes, in ms
const KEY = 'clearBells';

// set the clear flag so other pages will notice
export function clearBells() {
  sessionStorage.setItem(KEY, Date.now().toString());
}

// is the clear flag set?
function getClearBells() {
  const val = sessionStorage.getItem(KEY);
  if (!val) {
    return;
  }
  const elapsed = Date.now() - parseInt(val, 10);
  return elapsed < TTL;
}

export default function init() {
  // did the user hit back, and is the flag set? remove the navbar counts
  if (isBackButtonNav() && getClearBells()) {
    const $elements = document.querySelectorAll('.navbar-bells .navbar-badge-count');
    $elements.forEach($i => $i.parentNode!.removeChild($i));
  }
}
