import pluralize from 'pluralize';

interface ApiVoteResponse {
  up: number;
  down: number;
  nvotes: number;
  percent: number;
}

export function initVoting() {
  const $voting = document.querySelector('.voting');
  if (!$voting) {
    return;
  }

  $voting.addEventListener('ajax:success', (e: CustomEvent) => {
    // hide
    $voting.classList.remove('toggle');

    // render
    const data = e.detail[0] as ApiVoteResponse;
    const $rating = $voting.querySelector('.rating')!;
    const $num = $voting.querySelector('.num')!;
    $rating.innerHTML = `&ndash; ${data.percent}% like it`;
    $num.innerHTML = `(${data.nvotes} ${pluralize('vote', data.nvotes)})`;
  });
}
