// did the user hit the back button?
export default function isBackButtonNavigation() {
  // this API is deprecated, unfortunately. Is there a replacement?
  // https://developer.mozilla.org/en-US/docs/Web/API/Performance
  if (!(window.performance && window.performance.navigation)) {
    return false;
  }

  return window.performance.navigation.type === window.performance.navigation.TYPE_BACK_FORWARD;
}
