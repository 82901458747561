import filter from '../lib/filter';
import { search } from '../lib/search';

export default function action() {
  const $filters = document.querySelectorAll<HTMLElement>('.top-businesses .filter span');
  if ($filters) {
    const $targets = document.querySelectorAll<HTMLElement>('.top-businesses .group');
    filter($filters, $targets);
  }

  // search
  const $search = document.querySelector('.add-recommendation input.search') as HTMLInputElement;
  if ($search) {
    search($search, { autoScroll: true });
  }
}
