import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const VARIANTS = ['outline', 'text'];

/**
 * Try to keep these options nsync with HtmlTag::Btn in the Ruby code.
 */
@Component({})
export default class AppBtn extends Vue {
  @Prop(Boolean)
  public secondary: boolean;

  @Prop(Boolean)
  public danger: boolean;

  @Prop(Boolean)
  public light: boolean;

  @Prop(Boolean)
  public dark: boolean;

  @Prop({
    type: String,
    validator: i => VARIANTS.includes(i),
  })
  public variant: 'outline' | 'text';

  @Prop(Boolean)
  public lg: boolean;

  @Prop(Boolean)
  public sm: boolean;

  @Prop(Boolean)
  public xs: boolean;

  @Prop(Boolean)
  public block: string;

  @Prop(String)
  public icon: string;

  /**
   * Should be a URL. Will change it to an <a> tag instead of a <button> tag.
   * It's call `to` to match vue-router's router-link components.
   */
  @Prop(String)
  public to: string;

  @Prop(String)
  public target: string;

  get tag() {
    return this.to ? 'a' : 'button';
  }

  get classes() {
    const classes = ['btn'];

    let color = 'primary';
    for (const i of ['secondary', 'danger', 'light', 'dark']) {
      if (this[i]) {
        color = i;
        break;
      }
    }

    if (this.variant === 'text') {
      classes.push(`btn-outline-${color}`);
      classes.push('btn-text');
    } else if (this.variant === 'outline') {
      classes.push(`btn-outline-${color}`);
    } else {
      classes.push(`btn-${color}`);
    }

    for (const size of ['lg', 'sm', 'xs']) {
      if (this[size]) {
        classes.push(`btn-${size}`);
        break;
      }
    }

    if (this.block) {
      classes.push('btn-block');
    }

    return classes;
  }

  get iconClasses() {
    const classes = ['i'];

    if (this.icon) {
      classes.push(this.icon);
    }

    // Default slot would be the button text.
    if (!this.$slots.default) {
      classes.push('no-text');
    }

    return classes;
  }

  public click(e: Event) {
    this.$emit('click', e);
  }
}
