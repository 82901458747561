import anime from 'animejs';
import LazyLoad from 'vanilla-lazyload/dist/lazyload.js';
import { getBreakpoint } from '../lib/env';
import { Memoize } from '../lib/memoize';

//
// TODO
//
// there is a bug here with super wide screens (20% width stops working)
//

export default function action() {
  new Action();
}

class Action {
  private readonly sliders: Slider[];

  constructor() {
    const $sliders = Array.from(document.querySelectorAll('.slider'));
    this.sliders = $sliders.map($i => new Slider($i as HTMLElement));

    // turn on lazy loading
    new LazyLoad();
  }
}

//
// a single slider of items (row)
//

class Slider {
  private readonly $slider: HTMLElement;
  private pos = 0;

  constructor($slider: HTMLElement) {
    this.$slider = $slider;

    // setup
    this.setPosition(0);

    // events
    window.addEventListener('resize', () => this.onWindowResize());
    this.$handleLeft.addEventListener('click', () => this.onHandleLeftClick());
    this.$handleRight.addEventListener('click', () => this.onHandleRightClick());
  }

  // see products.scss
  get itemsVisible(): number {
    return {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 5,
      xl: 5,
    }[getBreakpoint()];
  }

  private setPosition(pos: number, animate = false) {
    // update state
    this.pos = pos;

    // cache this
    const itemsVisible = this.itemsVisible;

    // set handle visibility
    const nextIndex = this.pos + itemsVisible;
    this.$handleLeft.style.visibility = this.pos > 0 ? 'visible' : 'hidden';
    this.$handleRight.style.visibility = this.$items.length > nextIndex ? 'visible' : 'hidden';

    // translate
    const duration = animate ? itemsVisible * 100 : 0;
    anime({
      duration,
      easing: 'easeInOutQuad',
      targets: this.$animate,
      translateX: `-${(this.pos / itemsVisible) * 100}%`,
    });
  }

  //
  // events
  //

  private onWindowResize() {
    this.setPosition(0);
  }

  private onHandleLeftClick() {
    this.setPosition(this.pos - this.itemsVisible, true);
  }

  private onHandleRightClick() {
    this.setPosition(this.pos + this.itemsVisible, true);
  }

  //
  // accessors
  //

  @Memoize() private get $animate() {
    return this.$slider.querySelector('.animate') as HTMLElement;
  }

  @Memoize() private get $items() {
    return Array.from(this.$slider.querySelectorAll('.item')) as HTMLElement[];
  }

  @Memoize() private get $handleLeft() {
    return this.$slider.querySelector('.handle.left') as HTMLElement;
  }

  @Memoize() private get $handleRight() {
    return this.$slider.querySelector('.handle.right') as HTMLElement;
  }
}
