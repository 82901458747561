//
// Global clipboard handler for data-clipboard-text.
//

import Clipboard from 'clipboard';
import delegate from 'delegate';
import { buildFromHtml } from './dom';

export default function init() {
  const clipboard = new Clipboard('[data-clipboard-text]');

  // eat clicks to avoid conflicts with ancestor data-href
  delegate('[data-clipboard-text]', 'click', e => e.preventDefault());

  // show #copied after clipboard success
  clipboard.on('success', showClipboardIndicator);
}

export function showClipboardIndicator() {
  // remove old #copied if present
  const $old = document.querySelector('#copied');
  if ($old) {
    $old.parentElement!.removeChild($old);
  }

  // create new #copied
  const $el = buildFromHtml('<div id="copied">Copied to clipboard</div>');
  document.body.appendChild($el);
}
