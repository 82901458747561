//
// Handles hiding the register prompt for the rest of the session when
// a user clicks the close button
//

import MicroModal from 'micromodal';
import { track } from './track';
import { wait } from './util';

const SELECTOR = '.register-prompt';
const STORAGE_KEY = 'register-prompt-dismissed';

export default function init() {
  const $prompt = document.querySelector(SELECTOR) as HTMLElement;
  if (!$prompt) {
    return;
  }
  if (isDismissed()) {
    hide();
    return;
  }
  // If this is the modal variant
  if ($prompt.classList.contains('modal')) {
    addEventListener('scroll', showModal);
    return;
  }
  const $close = $prompt.querySelector('.close-btn') as HTMLButtonElement;
  $close?.addEventListener('click', () => {
    setDismissed();
    hide();
  });
  // This needs to be delayed slightly so that Amplitude has time to initialize
  setTimeout(() => track('View Register Prompt'));
}

async function showModal() {
  removeEventListener('scroll', showModal);
  // Don't show this prompt on mobile guides, because they already have branding
  if (document.documentElement.classList.contains('mobile-guide')) {
    return;
  }
  await wait(30000);
  MicroModal.show('register-prompt-modal', {
    disableFocus: true,
    disableScroll: true,
    onClose: () => setDismissed(),
  });
  track('View Register Prompt', { type: 'modal' });
}

// Returns whether the prompt is disabled for this session
function isDismissed(): boolean {
  return JSON.parse(sessionStorage.getItem(STORAGE_KEY) || 'false');
}

// Sets whether this prompt should be disabled for this session
function setDismissed(value = true) {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(value));
}

// Hides the prompt from the UI in the current page
function hide() {
  const $prompt = document.querySelector(SELECTOR) as HTMLElement;
  if (!$prompt) {
    return;
  }
  $prompt.remove();
}
