//
// Provides safe `position: sticky;` for elements that might be taller than the screen
//
// Add a `safe-sticky-top` class to elements that should use this feature. It will
// enable sticky-top only if the full height of the element fits on screen. If an
// element is too tall, sticky-top will be disabled.
//

const CLASS_NAME = 'sticky-top'; // The name of the class to use when elements should be sticky
let $targets: NodeListOf<HTMLElement>; // Elements that will be stuck at the edge of the screen

export default function init() {
  $targets = document.querySelectorAll('.safe-sticky-top');
  if (!$targets.length) {
    return;
  }
  update();
  window.addEventListener('resize', update);
}

function update() {
  const screenHeight = window.innerHeight;
  $targets.forEach($target => {
    const { height } = $target.getBoundingClientRect();
    $target.classList.toggle(CLASS_NAME, height < screenHeight);
  });
}
