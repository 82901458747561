type KeygenFunction<K = any> = (key: K) => any;

/**
 * Very similar to a normal Map, except it allows a keygen function to be passed
 * that will modify the key before getting/setting. This is handy for stuff like
 * autocomplete responses where we want to normalize the query.
 */
export class CacheMap<K = any, V = any> {
  private readonly cache = new Map<any, V>();

  /**
   *
   * @param keygenFunction The function that the key will run through. Whatever
   * is returned from this function will be used as the key for lookups.
   */
  constructor(private keygenFunction: KeygenFunction<K>) {}

  public get(key: K) {
    return this.cache.get(this.getProcessedKey(key));
  }

  public set(key: K, value: V) {
    this.cache.set(this.getProcessedKey(key), value);
    return this;
  }

  private getProcessedKey(key: K) {
    return this.keygenFunction(key);
  }
}
