//
// Animate an element's numeric value upward over a specified duration (in ms)
//

export default function countUp($element: HTMLElement, duration: number) {
  const elementValue = $element.innerText;
  const range = parseInt(elementValue.replace(/,/g, ''), 10);
  if (isNaN(range) || range < 10) {
    return;
  }

  let stepValue = 10;
  // attempt to use a step of 10
  let stepCount = Math.floor(range / stepValue);
  let stepTime = Math.floor(duration / stepCount);

  // correct stepValue if timing is too aggressive
  stepTime = Math.max(10, stepTime); // min stepTime is 10ms
  stepCount = Math.ceil(duration / stepTime);
  stepValue = Math.ceil(range / stepCount);

  let currentStep = 0;

  function run() {
    if (currentStep === stepCount) {
      clearInterval(timer);
    }

    // Math.min incase we overshoot (due to rounding)
    const value = Math.min(currentStep * stepValue, range);
    $element.innerText = value.toLocaleString();
    currentStep++;
  }

  const timer = setInterval(run, stepTime);
  run();
}
