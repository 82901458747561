const ONBOARDING_KEY = 'onboarding';
const INVITING_KEY = 'inviting';

//
// Onboarding
//

/**
 * Once they start the onboarding flow, how long should we still consider them
 * in the flow if they leave the site and come back?
 */
const ONBOARDING_TTL = 60 * 60 * 3 * 1000; // ms

export function setIsOnboarding() {
  sessionStorage.setItem(ONBOARDING_KEY, Date.now().toString());
}

export function clearIsOnboarding() {
  return sessionStorage.removeItem(ONBOARDING_KEY);
}

export function isOnboarding() {
  const val = sessionStorage.getItem(ONBOARDING_KEY);

  return !!val && Date.now() - parseInt(val, 10) < ONBOARDING_TTL;
}

//
// Inviting
// Used in the connect section to put it into invite-only mode.
//

export function setIsInviting() {
  // We either set or clear every time they go into the connect page, so we
  // don't need to store the date or do TTL checks on the key.
  sessionStorage.setItem(INVITING_KEY, '');
}

export function clearIsInviting() {
  return sessionStorage.removeItem(INVITING_KEY);
}

export function isInviting() {
  return sessionStorage.getItem(INVITING_KEY) === '';
}
