import * as Cookies from 'js-cookie';
import { CitySuggestion, search } from '../lib/search';
import { mobile } from '../lib/userAgent';

export default function action() {
  const a = new Action();
}

class Action {
  private static readonly cookieName = 'searchcity';

  constructor() {
    // Initialize search
    search(this.$search, {
      beforeNavigate: (suggestion: CitySuggestion) => {
        this.saveCityId(suggestion.id);
        return suggestion.url;
      },
      autoScroll: mobile,
      autoFocus: !mobile,
    });

    // Set up click handlers on all city links
    document.querySelectorAll('a[data-city-id]').forEach($a => {
      $a.addEventListener('click', this.onClickCity.bind(this));
    });
  }

  private onClickCity(e) {
    const id = e.currentTarget.dataset.cityId;
    this.saveCityId(id);
  }

  private saveCityId(id) {
    Cookies.set(Action.cookieName, id);
  }

  //
  // getters
  //

  private get $search() {
    return document.querySelector('.search-cities input.search') as HTMLInputElement;
  }
}
