import Cookies from 'js-cookie';
import { compactObject } from './util';

const DEBUG = true;

export interface Tracker {
  init(userId: string | undefined, optOut: boolean): void;
  register(properties: Dict): void;
  track(event: string, properties?: Dict): Promise<any> | null;
}

const trackers: Tracker[] = []; //new AmplitudeTracker(DEBUG), new GaTracker(DEBUG)];

// Get the rails action for the current page (off the body)
export function getPageAction(): string | undefined {
  return document.querySelector('body')?.dataset.action;
}

// Get tracking properties from the page body
export function getPageTrackProperties(): Dict {
  const trackAttr = document.querySelector('body')?.dataset.track;
  if (trackAttr?.charAt(0) !== '{') {
    return {};
  }

  const properties = JSON.parse(trackAttr) as Dict | undefined;
  if (!properties) {
    throw new Error(`Couldn't parse JSON.`);
  }
  delete properties.event;
  delete properties.register;
  return properties;
}

export function initTrack() {
  // Get current user id, if available
  const userId = document.body.dataset.uid;

  // check opt-out cookie
  const optOut = !!Cookies.get('nomp');

  // Initialize trackers
  trackers.forEach(i => i.init(userId, optOut));
  trackNode(document);
}

// Initialize tracking for a node and all its descendants based
// on data-track.
export function trackNode($node: ParentNode) {
  const selector = '[data-track]';
  const $tracks = Array.from($node.querySelectorAll(selector));

  // If we're dealing with an Element (as opposed to a Document),
  // check it for tracking data too.
  if (isElement($node) && $node.matches(selector)) {
    $tracks.unshift($node);
  }

  $tracks.forEach(($el: HTMLElement) => {
    //
    // parse $el.track and pull out event, properties and register
    //

    let event: string;
    let properties: Dict | undefined;
    let register: Dict | undefined;

    // We know that the track data attribute is set since the query we do above
    // is literally any element with a track data attribute.
    const trackAttr = $el.dataset.track!;
    if (trackAttr.charAt(0) !== '{') {
      event = trackAttr;
    } else {
      properties = JSON.parse(trackAttr);
      if (!properties) {
        throw new Error(`Couldn't parse JSON.`);
      }
      event = properties.event as string;
      register = properties.register as Dict | undefined;
      delete properties.event;
      delete properties.register;
      if (!event) {
        throw new Error('dataset.track.event not found in `$el.dataset.track`');
      }
    }

    //
    // register super properties. Do first so they get included in body track.
    //

    if (register) {
      for (const tracker of trackers) {
        tracker.register(register);
      }
    }

    //
    // There are a few different cases to handle:
    //
    // <body data-track=..
    // form.submit
    // anything.click
    //

    if ($el.tagName === 'BODY') {
      // page tracking
      track(event, properties);
    } else if ($el.classList.contains('track')) {
      // immediate tracking elsewhere on page
      track(event, { ...properties, action: getPageAction() });
    } else if ($el.tagName === 'FORM') {
      // form submit tracking
      $el.addEventListener('submit', () => track(event, properties));
    } else {
      // click tracking
      $el.addEventListener('click', () => track(event, { ...properties, action: getPageAction() }));
    }
  });
}

//
// track an event
//

export async function track(event: string, properties?: Dict, callback?: () => void) {
  let copy: Dict | undefined;
  if (properties) {
    copy = compactObject(properties);
  }

  await Promise.all(trackers.map(i => i.track(event, copy)));
  if (callback) {
    callback();
  }
}

//
// helpers
//

// Typeguard
// http://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
function isElement($node: ParentNode): $node is Element {
  return ($node as Element).matches !== undefined;
}
