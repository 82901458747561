import { search } from '../lib/search';
import { mobile } from '../lib/userAgent';

export function initFriendSearch() {
  // search
  const $search = document.querySelector('.search-friends input.search') as HTMLInputElement;
  if ($search) {
    search($search, { context: 'users', autoScroll: false, autoFocus: !mobile });
  }
}
