//
// strings
//

/** capitalize first letter of string */
export function capitalize(s: string): string {
  return `${s.charAt(0).toLocaleUpperCase()}${s.slice(1)}`;
}

/** escape string for use in HTML */
export function escape(s: string): string {
  const ESCAPE = { '&': '&amp;', '<': '&lt;', '>': '&gt;', '"': '&quot;', "'": '&#39;' };
  return s.replace(/[&<>"']/g, c => ESCAPE[c]);
}

/** escape string for use in a regexp */
export function escapeRegExp(s: string): string {
  return s.replace(/[\\^$.*+?()[\]{}|]/g, '\\$&');
}

/** random alpha chars */
export function randomAlpha(n: number): string {
  const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'.split('');
  return Array(n)
    .fill(0)
    .map(() => sample(ALPHABET))
    .join('');
}

/** helloWorld => hello_world */
export function snakeCase(s: string): string {
  return s.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

/** squish whitespace in a string */
export function squish(s: string): string {
  return s.trim().replace(/\s+/g, ' ');
}

/** truncate a string and cap with ... if too long */
export function truncate(s: string, options: { length: number }) {
  return s.length > options.length ? `${s.substring(0, options.length - 3)}...` : s;
}

//
// arrays
//

/** remove falsey values from an array */
export function compact<T>(array: T[]): T[] {
  return array.filter(Boolean);
}

/** turn array into an object, using 'key' to determine the key name from each property */
export function keyBy<T>(array: T[], key: string): Record<string, T> {
  return array.reduce((memo, i) => {
    memo[i[key]] = i;
    return memo;
  }, {});
}

/** return a random element from an array */
export function sample<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

/** sort an array using a map fn */
export function sortBy<T>(array: T[], fn: Mapper<T>): T[] {
  const pairs = array.map(i => [fn(i), i]);
  pairs.sort(([a], [b]) => (a < b ? -1 : a === b ? 0 : 1));
  return pairs.map(pair => pair[1]);
}

//
// array math
//

/** a & b */
export function intersect<T>(a: T[], b: T[]): T[] {
  return a.filter(i => b.includes(i));
}

/** a - b */
export function subtract<T>(a: T[], b: T[]): T[] {
  return a.filter(i => !b.includes(i));
}

/** a | b */
export function union<T>(a: T[], b: T[]): T[] {
  return a.concat(subtract(b, a));
}

//
// objects
//

type MapKeysMapper = (value: any, key: string) => string;

/** create new object by mapping keys from object */
export function mapKeys(object: Record<string, any>, map: MapKeysMapper): Record<string, any> {
  return Object.keys(object).reduce((memo, key) => {
    const value = object[key];
    memo[map(value, key)] = value;
    return memo;
  }, {});
}

/** remove null/undefined properties from an object */
export function compactObject<T>(object: T): T {
  return Object.keys(object).reduce((memo, key) => {
    const value = object[key];
    if (value !== undefined && value !== null) {
      memo[key] = value;
    }
    return memo;
  }, {} as T);
}

/** return object minus some keys */
export function omit<T, K extends keyof T>(object: T, pathOrPaths: K | K[]): Omit<T, K> {
  const paths = (pathOrPaths instanceof Array ? pathOrPaths : [pathOrPaths]) as string[];
  return objectFromKeys(object, subtract(Object.keys(object), paths));
}

/** return object, but only specified keys */
export function pick<T, K extends keyof T>(object: T, pathOrPaths: K | K[]): Pick<T, K> {
  const paths = (pathOrPaths instanceof Array ? pathOrPaths : [pathOrPaths]) as string[];
  return objectFromKeys(object, intersect(Object.keys(object), paths));
}

//
// min/max
//

/** find min value */
export function min<T>(array: T[]): T | undefined {
  return findBest(array, identity, (a, b) => a < b);
}

/** find max value */
export function max<T>(array: T[]): T | undefined {
  return findBest(array, identity, (a, b) => a > b);
}

/** find min value, using map fn */
export function minBy<T>(array: T[], map: Mapper<T>): T | undefined {
  return findBest(array, map, (a, b) => a < b);
}

/** find max value, using map fn */
export function maxBy<T>(array: T[], map: Mapper<T>): T | undefined {
  return findBest(array, map, (a, b) => a > b);
}

//
// time
//

/** returns a Promise that resolves when the specified ms has elapsed */
export function wait(ms: number): Promise<void> {
  return new Promise((resolve, _reject) => {
    setTimeout(resolve, ms);
  });
}

//
// functions
//

/** return passed in object */
export function identity<T>(i: T): T {
  return i;
}

/** empty function */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop() {}

/**
 * returns a function that only calls fn once, regardless of how many times you
 * call it
 */
export function once(fn: any) {
  let result;
  let called: boolean;
  return function (...args) {
    if (!called) {
      called = true;
      result = fn.apply(this, args);
    }
    return result;
  };
}

//
// internal helpers
//

/** Find the best item from array using comparator. Run objects through map first */
function findBest<T>(array: T[], map: Mapper<T>, fn: Comparator<T>): T | undefined {
  let best: T | undefined;
  let bestValue: any;
  array.forEach(i => {
    const v = map(i);
    if (best === undefined || fn(v, bestValue)) {
      best = i;
      bestValue = v;
    }
  });
  return best;
}

/** copy object, but only some keys */
function objectFromKeys(object: any, keys: string[]): any {
  return keys.reduce((memo, key) => {
    memo[key] = object[key];
    return memo;
  }, {});
}

//
// types that we reuse
//

type Comparator<T> = (a: T, b: T) => boolean;
type Mapper<T> = (value: T) => any;
