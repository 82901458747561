import { mountVueComponent } from '../lib/vue';
import AppCustomEmailInvite from '../onboard/components/custom-email-invite.vue';

interface ApiContact {
  name?: string;
  email: string;
}

export default function action() {
  initializeEmailComponent();
}

function initializeEmailComponent() {
  // Vue component
  const $div = document.querySelector('#custom-email-invite') as HTMLDivElement;
  const $invite = mountVueComponent('#custom-email-invite', AppCustomEmailInvite);
  // Form elements
  const $form = $div.parentElement!.querySelector('form') as HTMLFormElement;
  const $emails = $form.querySelector('#object_emails')! as HTMLInputElement;
  const $message = $form.querySelector('#object_message')! as HTMLInputElement;
  // Set initial values
  ($invite as any).setMessage($message.value || $div.getAttribute('data-default-message')!);
  ($invite as any).setTo($emails.value);
  // When the "Send" button in the component is pressed
  $invite.$on('invite', (to: ApiContact[], message: string) => {
    // Fill in the hidden form
    $emails.value = to.map(x => x.email).join(',');
    $message.value = message;
    // Send the request
    $form.submit();
  });
}
