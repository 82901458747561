import loadGoogleMapsApi from 'load-google-maps-api';
import Routes from '../routes';
import { PlaceSuggestion } from '../search';

// "places javascript" key
const GOOGLE_MAPS_API_KEY = 'AIzaSyClkw6Ekhw4zLk2O4uyhKjzKGmkvs7jvl4';
const TIMEOUT = 2000;
const RADIUS = 48280; // 30 miles

export default class Places {
  private readonly loc: number[];
  private latLng: google.maps.LatLng;
  private sessionToken: google.maps.places.AutocompleteSessionToken;
  private service: google.maps.places.AutocompleteService;

  constructor(loc: number[]) {
    this.initApi();
    this.loc = loc;
  }

  public async getSuggestions(q: string): Promise<PlaceSuggestion[]> {
    // We asynchronously load the maps api on creation. If it's not ready yet,
    // just bail out.
    if (!this.service) {
      console.log('places ac not yet ready');
      return [];
    }

    this.ensureSession();

    const places = await this.safeFetchPredictions(q);

    const suggestions: PlaceSuggestion[] = places.map(place => {
      let name: string;
      let location: string | undefined;

      // Try to use structured data, which contains two lines. If it's missing,
      // fall back to just the full description on one line.
      if (place.structured_formatting) {
        name = place.structured_formatting.main_text;
        location = place.structured_formatting.secondary_text;
        if (location) {
          // Very simple normalization.
          location = location.replace(/, (USA|Canada)$/, '');
        }
      } else {
        name = place.description;
      }

      // Pass the session token so that we can reuse this session for the details request.
      // Not necessary, but saves getting double-charged.
      const tokenStr = Object.values(this.sessionToken)[0];
      const url = Routes.url(Routes.newBusiness(), { pid: place.place_id, sid: tokenStr });

      return {
        type: 'place',
        place_id: place.place_id,
        session: tokenStr,
        name,
        location,
        url,
      };
    });

    return suggestions;
  }

  // Generate a new session token
  public resetSession() {
    if (!this.service) {
      console.log('places ac not yet ready');
      return;
    }

    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
  }

  // Fetch predictions, but apply timeout and catch all errors.
  private async safeFetchPredictions(
    q: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const promise = this.fetchPredictions(q);
    const timeout = new Promise((resolve, reject) => {
      setTimeout(() => reject(Error('Timeout')), TIMEOUT);
    }) as Promise<google.maps.places.AutocompletePrediction[]>;

    try {
      return await Promise.race([promise, timeout]);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }

  private async fetchPredictions(q: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve, reject) => {
      this.service.getPlacePredictions(
        {
          componentRestrictions: { country: ['us', 'ca'] },
          input: q,
          location: this.latLng,
          radius: RADIUS,
          sessionToken: this.sessionToken,
          types: ['establishment'],
        },
        (
          predictions: google.maps.places.AutocompletePrediction[] | null,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          resolve(predictions || []);
        },
      );
    });
  }

  private async initApi() {
    try {
      await loadGoogleMapsApi({
        key: GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
        language: 'en',
        region: 'US',
      });
      this.service = new google.maps.places.AutocompleteService();
      this.latLng = new google.maps.LatLng(this.loc[0], this.loc[1]);
    } catch (error) {
      console.error('loadGoogleMapsApi error', error);
    }
  }

  private ensureSession() {
    if (!this.sessionToken) {
      this.resetSession();
    }
  }
}
