var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (image_url, nameBolded, price, tag_name) {
      if (image_url) {
pug_html = pug_html + "\u003Cdiv class=\"has-image\"\u003E\u003Cdiv class=\"product-photo\"\u003E\u003Cimg" + (pug.attr("src", image_url, true, true)) + "\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"lines\"\u003E\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name\"\u003E" + (null == (pug_interp = nameBolded) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"l2\"\u003E";
if (price) {
pug_html = pug_html + "\u003Cdiv class=\"tag\"\u003E" + (null == (pug_interp = tag_name) ? "" : pug_interp) + " &bull; " + (null == (pug_interp = price) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"tag\"\u003E" + (null == (pug_interp = tag_name) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"lines\"\u003E\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name\"\u003E" + (null == (pug_interp = nameBolded) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"l2\"\u003E";
if (price) {
pug_html = pug_html + "\u003Cdiv class=\"tag\"\u003E" + (null == (pug_interp = tag_name) ? "" : pug_interp) + " &bull; " + (null == (pug_interp = price) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
else {
pug_html = pug_html + "\u003Cdiv class=\"tag\"\u003E" + (null == (pug_interp = tag_name) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
}
    }.call(this, "image_url" in locals_for_with ?
        locals_for_with.image_url :
        typeof image_url !== 'undefined' ? image_url : undefined, "nameBolded" in locals_for_with ?
        locals_for_with.nameBolded :
        typeof nameBolded !== 'undefined' ? nameBolded : undefined, "price" in locals_for_with ?
        locals_for_with.price :
        typeof price !== 'undefined' ? price : undefined, "tag_name" in locals_for_with ?
        locals_for_with.tag_name :
        typeof tag_name !== 'undefined' ? tag_name : undefined));
    ;;return pug_html;};
module.exports = template;