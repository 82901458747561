import autocomplete from 'autocomplete.js';
import zepto from 'autocomplete.js/zepto';
import pluralize from 'pluralize';
import boldMatch from '../boldMatch';
import { CacheMap } from '../cacheMap';
import csrfFetch from '../csrfFetch';
import { fcMetadata } from '../fc_metadata';
import { KeyArrowDown, KeyArrowLeft, KeyArrowRight, KeyArrowUp, KeyTab } from '../keyCodes';
import Routes from '../routes';
import Places from '../search/places';
import { track } from '../track';
import { squish } from '../util';
// templates
import business from './business.pug';
import city from './city.pug';
import defaultTemplate from './default.pug';
import footer_empty from './footer_empty.pug';
import footer_loggedin_navbar from './footer_loggedin_navbar.pug';
import footer_users from './footer_users.pug';
import place from './place.pug';
import product from './product.pug';
import tag from './tag.pug';
import user from './user.pug';

//
// Autocomplete search using the Algolia autocomplete library, which is based on
// the original Bootstrap typeahead library.
//
// see https://github.com/algolia/autocomplete.js
//

export function search($input: HTMLInputElement, options: Options = {}) {
  new Search($input, options);
}

const DEFAULTS = {
  autoselect: true,
  openOnFocus: true,
  // debug: true,
  tabAutocomplete: false,
  clearOnSelected: false,
};

const DEFAULT_LIMIT = 10;

/**
 * The amount of time in ms to wait before allowing to press enter after typing
 * into the input.
 */
const KEY_GRACE_PERIOD = 400;

const IDLE_TIMEOUT = 2000;

const ARROW_KEYS = [KeyArrowUp, KeyArrowRight, KeyArrowDown, KeyArrowLeft];

const TAG_DESCRIPTIONS = {
  business_category: 'business',
  product_category: 'product',
};

class LastSearch {
  // the user-provided search query
  public readonly query: string = '';

  // unique list of types that the search contained
  public readonly types: string[] = [];

  // the number of search results
  public readonly count: number = 0;

  constructor(query: string, suggestions: Suggestion[]) {
    this.query = query;
    const types = new Set<string>();
    for (const suggestion of suggestions) {
      types.add(suggestion.type);
    }
    this.types = Array.from(types);
    this.count = suggestions.length;
  }

  public get trackProperties(): Dict {
    return {
      query: this.query,
      types: this.types,
      count: this.count,
    };
  }
}

class Search {
  // search box
  private $input: HTMLInputElement;

  // search options
  private readonly options: Options;

  // simple internal cache to minimize network requests
  private readonly cache = new CacheMap<string, Suggestion[]>(q => squish(q).toLowerCase());

  // autocomplete object from Algolia
  private autocomplete: any;

  // store the last search ran so we can track properly
  private lastSearch: LastSearch | null;

  // the timeout for knowing when they've become idle
  private idleTimeout: any = null;

  // if this is set, we will query google places as well
  private readonly places: Places | null = null;

  //
  // ctor
  //

  constructor($input: HTMLInputElement, options: Options) {
    this.$input = $input;
    this.options = { ...options };

    const path = this.searchUrl;
    if (!path) {
      throw new Error('must specify data-search-url');
    }

    // Check to see if we are making a google places call. Read from options ||
    // dataset. If true, infer from fcMetadata.user_city.
    let places = this.options.places;
    if (places === undefined && this.$input.dataset.places) {
      places = JSON.parse(this.$input.dataset.places);
    }
    if (places === true) {
      if (fcMetadata.user_city.ghost_town) {
        places = fcMetadata.user_city.loc;
      } else {
        places = false;
      }
    }
    if (Array.isArray(places)) {
      this.places = new Places(places);
    }

    const templateFooter: string | undefined = this.options.context
      ? TEMPLATES[`footer_${this.options.context}`]
      : undefined;

    // Dataset. The typeahead widget supports "hinting", which shows the top
    // suggestion with enticing light gray text in the input box. In order for
    // hinting to work we have to tell the widget how to convert a Suggestion
    // into a displayKey. This also tells the widget how to fill in the value
    // when an item is selected.
    //
    // Note that hinting is useless with the default suggestion we typically put
    // at the top, but if the default suggestion isn't present it looks good.
    const dataset = {
      // Use this for css styling of attribution image.
      // The dropdown menu is assigned class: aa-{{'with' or 'without'}}-{{name}}
      name: this.places ? 'places' : 'fc',
      debounce: 150,
      // this helps the widget fill in the hint
      displayKey: (suggestion: BaseSuggestion) => suggestion.name,
      source: this.onSource.bind(this),
      templates: {
        suggestion: (suggestion: BaseSuggestion) => TEMPLATES[suggestion.type](suggestion),
        footer: this.options.noFooter ? undefined : templateFooter,
        // For the footer to show even when no results are available, we have to
        // fill this in with an empty div. Otherwise it won't show the footer
        // when empty.
        empty: templateFooter ? '<div></div>' : footer_empty,
      },
    };

    // Construct autocomplete
    const autocompleteOptions: any = { ...DEFAULTS };
    if (options.appendTo) {
      autocompleteOptions.hint = false;
      autocompleteOptions.appendTo = options.appendTo;
    }
    if (options.clearOnSelected) {
      autocompleteOptions.hint = false;
      autocompleteOptions.clearOnSelected = true;
    }
    this.autocomplete = autocomplete($input, autocompleteOptions, [dataset]);

    // events
    this.autocomplete.on('autocomplete:selected', this.onAutocompleteSelected.bind(this));
    this.makeTabActAsDown();
    this.enterKeyGracePeriod();

    // scroll the form to the top of the screen to make room for the keyboard
    this.$input.addEventListener('focus', this.onInputFocus.bind(this));
    this.$input.addEventListener('blur', this.onInputBlur.bind(this));

    if (this.options.autoFocus) {
      this.$input.focus();
    }
  }

  private get searchUrl() {
    return this.options.url || this.$input.dataset.searchUrl;
  }

  // hack to make tab key work like down arrow
  private makeTabActAsDown() {
    // get Typeahead from $input zepto
    const typeahead = zepto(this.$input).data('aaAutocomplete');

    // copy down handler to tab handler. This is a strange internal mechanism
    // used by autocomplete.js.
    typeahead.input._callbacks.tabKeyed = typeahead.input._callbacks.downKeyed;

    // prevent default on TAB to suppress blur
    zepto(typeahead.input.$input).on('keydown', (e: KeyboardEvent) => {
      if (e.key === KeyTab) {
        e.preventDefault();
      }
    });
  }

  // This is intended to help people like Dustin who typed quickly and hit enter
  // without ever seeing autocomplete results, or only getting partial results.
  private enterKeyGracePeriod() {
    const typeahead = zepto(this.$input).data('aaAutocomplete');

    // keep track of last keypress time
    let lastKeyTm = Date.now();
    zepto(typeahead.input.$input).on('keydown', (e: KeyboardEvent) => {
      // Ignore arrow keys.
      if (ARROW_KEYS.indexOf(e.key) !== -1) {
        return;
      }

      lastKeyTm = Date.now();
    });

    const oldEnterKeyedHandler = typeahead.input._callbacks.enterKeyed.sync[0];
    typeahead.input._callbacks.enterKeyed.sync[0] = (type, $e) => {
      if (Date.now() - lastKeyTm < KEY_GRACE_PERIOD) {
        return;
      }
      oldEnterKeyedHandler(type, $e);
    };
  }

  //
  // event handlers
  //

  // dataset.source (get results from network)
  private async onSource(q: string, callback: (suggestions: Suggestion[]) => void) {
    this.setIdleTimeout();

    // cached?
    const cached = this.cache.get(q);
    if (cached) {
      this.lastSearch = new LastSearch(q, cached);
      return callback(cached);
    }

    // parse/format/cache
    const suggestions = await this.getSuggestions(q);
    for (const suggestion of suggestions as any[]) {
      // use 'any' for simplicity, because we are cavalier with types
      suggestion.karma = this.fmtKarma(suggestion.karma);
      suggestion.nameBolded = this.highlightQuery(suggestion.name);

      if ('count' in suggestion) {
        suggestion.count = this.fmtCount(suggestion);
      }

      if ('screen_name' in suggestion) {
        suggestion.screenNameBolded = this.highlightQuery(suggestion.screen_name);
      }
    }

    this.lastSearch = new LastSearch(q, suggestions);
    this.cache.set(q, suggestions);

    // return results to caller
    callback(suggestions);
  }

  private async triggerRecModal() {
    // This module has already been imported by lazy.ts. Use weak import
    // to avoid a second download. This will fail if lazy.ts was not successfully
    // downloaded.
    // https://webpack.js.org/api/module-methods/#magic-comments
    const module = await import(/* webpackMode: "weak" */ '../rec/rec');
    module.openRecModal();
  }

  private onAutocompleteSelected(_event: Event, suggestion?: Suggestion) {
    // This means they selected the "footer/empty" result.
    if (!suggestion) {
      switch (this.options.context) {
        case 'users':
          this.trackSearchEvent('Search Invite');
          window.location.href = Routes.usersInvite();
          break;
        case 'loggedin_navbar':
          this.triggerRecModal();
          this.trackSearchEvent('Search Open Rec Modal');
          break;
      }
      return;
    }

    this.clearIdleTimeout();
    this.trackSearchEvent('Search Select', {
      url: suggestion.url || '',
      type: suggestion.type,
      searchUrl: this.searchUrl,
    });

    let url: string | null = suggestion.url;

    // Invoke our callback. This can modify the url.
    // Returning null cancels the navigation
    if (this.options.beforeNavigate) {
      url = this.options.beforeNavigate(suggestion);
    }

    // Every time the user selects an item, we must reset the google places
    // session token.
    if (this.places) {
      this.places.resetSession();
    }

    if (url) {
      window.location.href = url;
    }
  }

  // Scroll the form to the top of the screen to make room for the keyboard and
  // the suggestions. scrollIntoView seems to get tripped up by the expanding
  // keyboard on android, so calculate scroll manually.
  private onInputFocus() {
    document.body.classList.add('search-focus');
    if (this.options.autoScroll) {
      const rect = this.$input.getBoundingClientRect();
      const offset = rect.top + window.pageYOffset - 8; // leave a little space at the top
      window.scrollTo({ top: offset, behavior: 'smooth' });
    }
  }

  private onInputBlur() {
    document.body.classList.remove('search-focus');
  }

  private setIdleTimeout() {
    this.clearIdleTimeout();
    this.idleTimeout = setTimeout(() => {
      this.trackSearchEvent('Search Idle');
      this.idleTimeout = null;
    }, IDLE_TIMEOUT);
  }

  private clearIdleTimeout() {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = null;
    }
  }

  //
  // network
  //

  // Get suggestions from the server
  private async getServerSuggestions(q: string): Promise<Suggestion[]> {
    const path = this.searchUrl;
    if (!path) {
      throw new Error(`No path to go to.`);
    }

    const params = { q };
    if (this.options.limit) {
      params['limit'] = this.options.limit;
    }
    const url = Routes.url(path, params);

    const response = await csrfFetch(url);
    if (response.status !== 200) {
      console.error(response);
      throw new Error(`${response.url} failed - ${response.status}`);
    }

    return (await response.json()) as Suggestion[];
  }

  // Get suggestions from google places. Returns empty array if we
  // aren't configured to use places.
  private async getPlaceSuggestions(q: string): Promise<Suggestion[]> {
    if (!this.places) {
      return [];
    }

    return this.places.getSuggestions(q);
  }

  private async getSuggestions(q: string): Promise<Suggestion[]> {
    // Make requests in parallel
    const allResults = await Promise.all([
      // Always get results from our server
      this.getServerSuggestions(q),

      // Optionally get results from google places. This will return immediately
      // if we're not configured for places.
      this.getPlaceSuggestions(q),
    ]);

    const [serverSuggestions, placeSuggestions] = allResults;

    // Merge the two lists. Server suggestions take priority.
    // Add place suggestions as necessary, but try to avoid dups.
    const limit = this.options.limit || DEFAULT_LIMIT;
    const combined: Suggestion[] = serverSuggestions.slice(0, limit);

    while (combined.length < limit && placeSuggestions.length > 0) {
      const suggestion = placeSuggestions.shift()!;

      // Try to avoid adding places suggestions that also came back from
      // the server. Braindead dedup based on name.
      if (!serverSuggestions.find(i => i.name === suggestion.name)) {
        combined.push(suggestion);
      }
    }

    return combined;
  }

  //
  // formatting
  //

  // 1234 => 1,234 results
  private fmtCount(s: TagSuggestion): string | undefined {
    const word = TAG_DESCRIPTIONS[s.tag_type] || 'result';
    return `${s.count.toLocaleString()} ${pluralize(word, s.count)}`;
  }

  // format karma
  private fmtKarma(karma: number) {
    if (!karma || karma < 10) {
      return;
    }
    if (karma < 1000) {
      return `${karma} karma`;
    }
    return `${(karma / 1000).toFixed(1)}k karma`;
  }

  // format name by bolding text that matches q
  private highlightQuery(name: string) {
    return boldMatch(this.q, name);
  }

  //
  // helpers
  //

  private get q() {
    return this.$input.value;
  }

  private trackSearchEvent(event: string, properties?: Dict) {
    const allProps = {
      // Add default properties to those passed in
      places: !!this.places,
      ...this.lastSearch?.trackProperties,

      ...properties,
    };

    track(event, allProps);
  }
}

//
// rendering templates
//

const TEMPLATES = {
  business,
  tag,
  city,
  default: defaultTemplate,
  place,
  product,
  user,
  footer_loggedin_navbar,
  // Always shown in the footer of user results, even when no results.
  footer_users,
};

//
// types
//

interface Options {
  /**
   * Search url. Can also be specified in $input.dataset.searchUrl
   */
  url?: string;

  /**
   * [lat, lon] for a google places search, or true to infer from page metadata.
   * Can also be specified in $input.dataset.places. If unset, we don't use
   * google places.
   */
  places?: number[] | boolean;

  /**
   * Append search menu to an alternate element (e.g. <body>). This also forces
   * hints off, per the algolia docs.
   */
  appendTo?: string;

  /**
   * Clear input box after selection an autocomplete result
   */
  clearOnSelected?: boolean;

  /**
   * If true, scroll automatically to the top of the screen.
   */
  autoScroll?: boolean;

  /**
   * If true, will autofocus the input on initialization.
   */
  autoFocus?: boolean;

  /**
   * The search context we're currently in. Undefined means "default".
   */
  context?: 'users' | 'loggedin_navbar';

  /**
   * Whether to not render a footer. Undefined means "false".
   */
  noFooter?: boolean;

  /**
   * Limit number of results returned
   */
  limit?: number;

  /**
   * Callback before navigating. Return a url to modify the default behavior.
   */
  beforeNavigate?(suggestion: Suggestion): string | null;
}

export type Suggestion =
  | BusinessSuggestion
  | ProductSuggestion
  | TagSuggestion
  | CitySuggestion
  | DefaultSuggestion
  | PlaceSuggestion;

// tslint:disable:interface-over-type-literal
type BaseSuggestion = {
  // these come from the server
  type: string;
  name: string;
  url: string;
};

export type ProductSuggestion = {
  id: number;
  tag_name: string;
  price: string;
} & BaseSuggestion;

export type BusinessSuggestion = {
  id: number;
  tag_name: string;
  karma: number | string;
  location: string;
  from: string;
} & BaseSuggestion;

export type TagSuggestion = {
  count: number;
  tag_type: string;
} & BaseSuggestion;

export type CitySuggestion = {
  id: number;
} & BaseSuggestion;

export type UserSuggestion = {
  id: number;
  screen_name: string;
  from: string;
  karma: number;
} & BaseSuggestion;

export type DefaultSuggestion = {
  tag_name?: string;
  phone?: string;
  email?: string;
  website?: string;
  address?: string;
  from: string;
} & BaseSuggestion;

// Google places
export type PlaceSuggestion = {
  place_id: string;
  location?: string;
  tag_name?: string;
  session?: string;
} & BaseSuggestion;
