export default function action() {
  // Polyfill placeholder-shown for older browsers.
  document.querySelectorAll('.float-label').forEach(($el: HTMLDivElement) => {
    const $input = $el.querySelector<HTMLInputElement>('[placeholder]');
    const $label = $el.querySelector<HTMLLabelElement>('label');
    if (!$input || !$label) {
      console.error(new Error(`Couldn't find input or label for float-label component.`));
      return;
    }

    // Since the label is now becoming the placeholder, let's update its text to
    // match that of the placeholder.
    $label.innerText = $input.placeholder;

    updatePlaceholder($input);
    $el.addEventListener('change', () => updatePlaceholder($input));
    $el.addEventListener('keyup', () => updatePlaceholder($input));
  });
}

function updatePlaceholder($el: HTMLInputElement) {
  $el.classList.toggle('placeholder-shown', !$el.value);
}
