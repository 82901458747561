// similar to https://w3bits.com/css-snowfall/
// example: .snowfall{ data: { flake: '🚀' } }

export default function snowfall() {
  const $el = document.querySelector('.snowfall') as HTMLElement;
  if ($el) {
    const s = new Snowfall($el);
  }
}

class Snowfall {
  private readonly $el: HTMLElement;
  private readonly $style: HTMLStyleElement;

  constructor($el: HTMLElement) {
    this.$el = $el;

    //
    // create <style> for our rules. reuse to avoid dup styles on resize.
    //

    this.$style = document.createElement('style');
    this.$style.setAttribute('type', 'text/css');
    document.head.appendChild(this.$style);

    //
    // setup and listen for resizes
    //

    this.setupAnimations();
    window.addEventListener('resize', () => this.setupAnimations());
  }

  private setupAnimations() {
    const w = this.$el.offsetWidth;
    const h = this.$el.offsetHeight;

    const css: string[] = [];
    css.push(`.snowfall .f:after { content: '${this.flake}' }`);

    for (let i = 1; i <= this.nflakes; ++i) {
      //
      // params
      //

      const t = Math.floor(h * ((Math.random() * 0.5) + 0.5)); // 50-100% of height
      const y0 = -t;
      const y1 = t + (0.4 * h);
      const dy = y1 - y0

      const x = Math.floor(Math.random() * w); // 0-100% of width
      const dx = 2;

      const delay = Math.random() * 10;
      const duration = Math.floor(Math.random() * 3) + 2;
      const size = Math.floor(Math.random() * 20) + 4;

      //
      // div styles
      //

      const anim = `snowfall-${i}`;
      css.push(`.snowfall .f:nth-of-type(${i}) {
        animation-name: ${anim};
        animation-delay: ${delay}s;
        animation-duration: ${duration}s;
        font-size: ${size}px;
      }`);

      //
      // keyframes
      //

      css.push(`@keyframes ${anim} {
        0% {
          transform: translate3d(${x + dx * 0}px, ${y0 + dy * 0.00}px, 0) rotate(${360 * 0.00}deg);
          opacity: 1;
        }
        25% {
          transform: translate3d(${x + dx * 1}px, ${y0 + dy * 0.25}px, 0) rotate(${360 * 0.25}deg);
        }
        50% {
          transform: translate3d(${x + dx * 0}px, ${y0 + dy * 0.50}px, 0) rotate(${360 * 0.50}deg);
        }
        75% {
          transform: translate3d(${x + dx * 2}px, ${y0 + dy * 0.75}px, 0) rotate(${360 * 0.75}deg);
          opacity: 1;
        }
        100% {
          transform: translate3d(${x + dx * 2}px, ${y0 + dy * 1.00}px, 0) rotate(${360 * 1.00}deg);
          opacity: 0;
        }
      }`);
    }

    //
    // inject css into $style (overwrite old stuff)
    //

    this.$style.firstChild?.remove();
    this.$style.appendChild(document.createTextNode(css.join("\n")));

    //
    // finally, append flake divs if necessary
    //

    if (!this.$el.querySelector('.f')) {
      const fragment = document.createDocumentFragment();
      for (let i = 1; i <= this.nflakes; ++i) {
        const $f = document.createElement('div');
        $f.classList.add('f');
        fragment.appendChild($f);
      }
      this.$el.appendChild(fragment);
    }
  }

  //
  // helpers
  //

  private get flake() {
    return this.$el.dataset.flake || '❄'
  }

  private get nflakes() {
    return this.$el.dataset.nflakes ? parseInt(this.$el.dataset.nflakes, 10) : 25;
  }
};
