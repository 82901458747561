//
// Personalize the page using fcMetadata. This is required for caches_action,
// since content read from the cache need to be personalized.
//

import { fcMetadata } from './fc_metadata';

export default function init() {
  const $loc = document.querySelector('.navbar-search-container .search-location .fake-link');
  if ($loc) {
    $loc.textContent = fcMetadata.user_city.name;
  }
}
