
export default function imageErrors() {
  // https://stackoverflow.com/questions/9126105/blank-image-encoded-as-data-uri
  const FALLBACK = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

  document.addEventListener('error', e => {
    const $el = e.target;
    if (!($el instanceof HTMLImageElement)) {
      return;
    }

    // be conservative, avoid infinite loops
    if ($el.dataset.imageErrors) {
      return;
    }
    $el.dataset.imageErrors = '1';

    $el.src = FALLBACK;
    $el.srcset = '';
  }, true)
}
