import autocomplete from 'autocomplete.js';
import { escape } from '../util';
import AutocompleteQuery from './autocompleteQuery';

// see https://github.com/algolia/autocomplete.js

const AUTOCOMPLETE_OPTIONS = {
  autoselect: true,
  tabAutocomplete: false,
};

interface Options {
  onSelect?(e: Event, item: string): void;
}

export default function tagAutocomplete($input: HTMLInputElement, options: Options = {}) {
  // dataset, using AutocompleteQuery
  if ($input.dataset.tags) {
    const tags: string[] = JSON.parse($input.dataset.tags);
    const query = new AutocompleteQuery(tags, 10);
    const dataset = {
      source: (q, callback) => callback(query.search(q).map(s => ({ value: s }))),
      templates: { suggestion: suggestion => escape(suggestion.value) },
    };
    const instance = autocomplete($input, AUTOCOMPLETE_OPTIONS, [dataset]);

    instance.on('autocomplete:selected', (e: Event, item?: { value: string }) => {
      if (options.onSelect && item) {
        options.onSelect(e, item.value);
      }
    });
  }
}
