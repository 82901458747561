import { escape, escapeRegExp } from './util';

export default function boldMatch(needle: string, haystack: string) {
  // HTML escape both needle and haystack before replacing
  const needleEscaped = escape(needle);
  const haystackEscaped = escape(haystack);

  // build regex (using regex escaping on the needle
  const re = new RegExp(`(${escapeRegExp(needleEscaped)})`, 'gi');

  // now replace
  return haystackEscaped.replace(re, '<b>$1</b>');
}
