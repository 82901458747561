export default function filter($filters: NodeList, $targets: NodeList) {
  // helper
  const setActive = ($filter: HTMLElement) => {
    if (!$filter) {
      return;
    }

    const value = $filter.dataset.filter;
    $filters.forEach(($f: HTMLElement) => {
      $f.classList.toggle('active', $f.dataset.filter === value);
    });
    $targets.forEach(($t: HTMLElement) => {
      $t.classList.toggle('active', $t.dataset.filter === value);
    });
  };

  // set active for default filter
  setActive($filters[0] as HTMLElement);

  // listen for clicks, set active
  const onClick = (e: MouseEvent) => setActive(e.target as HTMLElement);
  $filters.forEach($f => $f.addEventListener('click', onClick));
}
