// TODO: Repurpose reviews code
import { initThreads } from '../lib/threads';

export default function action() {
  initThreads();
  highlightHash();
}

/**
 * This will highlight content on the page based on any hash in the URL. For
 * example: `#review-1`
 */
function highlightHash() {
  const hash = window.location.hash;
  if (!hash) {
    return;
  }

  const match = /post-(\d+)/.exec(hash.substr(1));
  if (!match) {
    return;
  }

  const $el = document.querySelector(`.${match[0]}`);
  if (!$el) {
    return;
  }

  // Highlight
  $el.classList.add('reply-pill-message-highlight');
}
