// Call fetch, but automatically pass the csrf token from the meta tag
export default function csrfFetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const defaultHeaders = {};

  if (csrf()) {
    // We have a token, so merge it into the provided RequestInit.
    defaultHeaders['X-CSRF-Token'] = csrf();
  }

  // Also merge in default credentials, needed for cookies. We always want this.
  //
  // Details:
  //   By default, fetch won't send or receive any cookies from the server,
  //   resulting in unauthenticated requests if the site relies on maintaining
  //   a user session (to send cookies, the credentials init option must be set).
  //   Since Aug 25, 2017. The spec changed the default credentials policy to
  //   same-origin. Firefox changed since 61.0b13.
  const credentials = 'same-origin';

  // Merge without modifying the input
  let headers = init ? init.headers : null;
  headers = { ...defaultHeaders, ...headers };
  init = { ...init, headers, credentials };

  return fetch(input, init);
}

function csrf() {
  const $meta = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;
  return $meta ? $meta.content : null;
}
