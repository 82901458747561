import { containerMaxWidths } from './env';
import { search } from './search';
import { mobile } from './userAgent';

export default function initHomeHero() {
  const $hero = document.querySelector('.home-hero-base');
  if (!$hero) {
    return;
  }

  const $heroSearch = $hero.querySelector('input.search') as HTMLInputElement;
  if ($heroSearch) {
    search($heroSearch, { autoScroll: mobile, autoFocus: !mobile });
  }

  const $heroVideo = $hero.querySelector<HTMLVideoElement>('video');
  const $heroImg = $hero.querySelector<HTMLImageElement>('.video-container img');
  if ($heroVideo && $heroImg) {
    // There is no such thing as a responsive video source, so we have to
    // manually check the size of the window and load in either the square video
    // (for mobile/portrait) or the wide video (for desktop/landscape).
    const $source = document.createElement('source');
    $source.type = 'video/mp4';

    // change at sm breakpoint
    let imgSrc = '';
    if (window.innerWidth < containerMaxWidths.sm) {
      imgSrc = $heroImg.dataset.mobileSource!;
      $source.src = $heroVideo.dataset.mobileSource!;
    } else {
      imgSrc = $heroImg.dataset.desktopSource!;
      $source.src = $heroVideo.dataset.desktopSource!;
    }
    $heroVideo.append($source);
    // Give the video time to load in before trying to show the background
    // image. Hopefully this will reduce flicker for fast enough networks.
    setTimeout(() => ($heroImg.src = imgSrc), 500);
  }
}
