import anime from 'animejs';

//
// Highlights DOM elements to help draw the user's attention
//
// set [data-highlight='selector'] to scroll an element to the middle of the screen
// vertically. (if the element is not found immediately, it will copy the options to
// sessionStorage and retry on the next page load)
//
// add [data-highlight-focus] to focus on the element
// add [data-highlight-glow] to make the edges of the element(s) glow until clicked
// add [data-highlight-redirect] to redirect if the target isn't found on the current page
// add [data-highlight-zoom] to make the element briefly grow in size
//
// Tested in Chrome (Android & macOS), Safari (iOS & macOS), and Firefox
//

const SESSION_STORAGE_KEY = '__highlighter'; // The key used in sessionStorage
const BUTTON_GLOW_CLASS = 'highlighter-glow'; // The class used for styling the glow

export default function init() {
  // Check sessionStorage for pending highlights
  const pendingStr = sessionStorage.getItem(SESSION_STORAGE_KEY);
  // If there is a pending item
  if (pendingStr) {
    // This highlight is now being handled
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    // Attempt to highlight
    doHighlight(JSON.parse(pendingStr));
  }
  // Initialize elements that should trigger a highlight onclick
  document.querySelectorAll('[data-highlight]').forEach($el => {
    $el.addEventListener('click', () => {
      const options = {
        selector: $el.getAttribute('data-highlight')!,
        focus: $el.getAttribute('data-highlight-focus') !== null,
        glow: $el.getAttribute('data-highlight-glow') !== null,
        redirect: $el.getAttribute('data-highlight-redirect') || undefined,
        zoom: $el.getAttribute('data-highlight-zoom') !== null,
      };
      highlight(options);
    });
  });
}

interface HighlighterOptions {
  selector: string; // The CSS selector to find the target element
  focus?: boolean; // Whether to focus on the target
  glow?: boolean; // Whether to add an animated glow to the target
  redirect?: string; // Where to redirect for deferred operations
  zoom?: boolean; // Whether to make the target briefly grow in size
}
export function highlight(options: HighlighterOptions) {
  // If the highlight fails
  if (doHighlight(options) === false) {
    // Defer it until next page load
    deferHighlight(options);
  }
}

// Attempts to perform a highlight
function doHighlight(options: HighlighterOptions) {
  const $target = document.querySelector(options.selector) as HTMLElement;
  // If the target element isn't found
  if ($target == null) {
    // This failed (defer)
    return false;
  }
  // Attempt to scroll to place the target in the center of the screen
  const rect = $target.getBoundingClientRect();
  const scrollTop = Math.max(0, rect.top + rect.height / 2 - window.innerHeight / 2);
  anime({
    targets: document.documentElement,
    scrollTop: scrollTop,
    duration: 500,
    easing: 'easeInOutQuad',
    complete: () => {
      // Handle zoom
      if (options.zoom) {
        anime
          .timeline({
            targets: $target,
            duration: 500,
            easing: 'easeInOutQuad',
          })
          .add({
            scale: 1.5,
          })
          .add({
            scale: 1,
          })
          .play();
      }
    },
  });
  // If the element should receive focus
  if (options.focus) {
    // Focus on it
    $target.focus();
  }
  // If the element(s) should receive a glowing outline (and haven't already)
  if (options.glow && !$target.classList.contains(BUTTON_GLOW_CLASS)) {
    addGlow(options.selector);
  }
}

// Defers the given highlight operation until next page load
function deferHighlight(options: HighlighterOptions) {
  // Save this highlight in sessionStorage, so it can be tried on the next page
  sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(options));
  if (options.redirect) {
    location.assign(options.redirect);
  }
}

//
// Creates a glow around all elements that match the given selector. It would be
// nice if we could just add a glow class to the target element, but we have to
// jump through additional hoops. Unfortunately the ancestors need to be
// temporarily set to `overflow: visible` to avoid the edges of the glow being
// hidden. When the element is clicked we revert the overflow back to the prior
// setting. I tested the spots where the glow is currently used and it worked
// fine - use with caution!
//
function addGlow(selector: string) {
  const $targets = document.querySelectorAll(selector);
  // The ancestors whose overflow property was overridden
  const $ancestors = new Map();
  // Removes the glow on all targets when one is clicked (this needs to be defined
  // outside of `.forEach`, so that all targets use the same listener function)
  const listener = (event: MouseEvent) => {
    const $target = event.target! as HTMLElement;
    for (const [$el, overflow] of $ancestors.entries()) {
      $el.style.overflow = overflow;
    }
    $targets.forEach($target => {
      $target.classList.remove(BUTTON_GLOW_CLASS);
      $target.removeEventListener('click', listener);
    });
  };
  $targets.forEach($target => {
    // Loop through ancestors until we reach the body
    for (let $el = $target.parentElement; $el && $el !== document.body; $el = $el.parentElement) {
      if (getComputedStyle($el).overflow !== 'visible') {
        $ancestors.set($el, $el.style.overflow);
        $el.style.overflow = 'visible';
      }
    }
    $target.classList.add(BUTTON_GLOW_CLASS);
    $target.addEventListener('click', listener);
  });
}
