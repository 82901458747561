import delegate from 'delegate';
import { fcMetadata } from '../lib/fc_metadata';
import Map from '../lib/map';
import { Memoize } from '../lib/memoize';
import Routes from '../lib/routes';
import { track } from '../lib/track';

interface Metadata {
  tag: string;
  city: string;
  hood?: string;
}

export default function action() {
  const _a = new Action();
}

const LOCATION_OPTIONS = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};


class Action {
  private map: Map;

  constructor() {
    if (!this.$gmap) {
      return;
    }

    const { tag, city, hood } = fcMetadata.page as Metadata;
    this.map = new Map(this.$gmap, { tag, city, hood }, () => this.onMapLoaded());

    // resize map
    window.addEventListener('resize', () => this.onWindowResize());
    this.onWindowResize();

    // enable location prompt
    delegate('.prompt-location', 'click', () => {
      navigator.geolocation.getCurrentPosition(onLocationSuccess, onLocationError, LOCATION_OPTIONS);
      track('Click Location Prompt');
    });
  }

  //
  // events
  //

  // make map visible when loaded
  private onMapLoaded() {
    // become visible
    this.$gmapSticky.classList.add('loaded');
  }

  // resize map when window size changes. map should be 100vh - navbar height
  // with some fudge.
  private onWindowResize() {
    this.$gmapSticky.style.height = `calc(100vh - ${this.$navbar.offsetHeight + 30}px)`;
  }

  //
  // helpers
  //

  @Memoize() private get $gmap() {
    return document.querySelector('.gmap') as HTMLElement;
  }

  @Memoize() private get $gmapSticky() {
    return document.querySelector('.gmap-sticky') as HTMLElement;
  }

  @Memoize() private get $navbar() {
    return document.querySelector('.navbar') as HTMLElement;
  }

}

//
// callbacks
//

function onLocationSuccess(position) {
  const latitude  = position.coords.latitude;
  const longitude = position.coords.longitude;

  let url = window.location.href;
  url = Routes.url(url, { location: `${latitude},${longitude}` });
  window.location.href = url;
}

function onLocationError(error) {
  const $error = document.createElement('div');
  $error.classList.add('error');
  // 1 = denied
  // 2 = unavailable
  // 3 = timeout
  if (error.code == 1) {
    $error.innerText = "Oops, looks like your browser is blocking location. You'll have to re-enable it then refresh the page to try again.";
  } else {
    $error.innerText = "Oops, there was a problem getting your location. You'll have to try again later.";
  }
  document.querySelector('.prompt-location')?.replaceWith($error);
  track('Location Prompt Error', { code: error.code });
}
