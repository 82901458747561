import Clipboard from 'clipboard';
import delegate from 'delegate';
import MicroModal from 'micromodal';
import { showClipboardIndicator } from './clipboard';
import Routes, { mailto } from './routes';
import { getPageAction, track } from './track';
import transition from './transition';
import { android } from './userAgent';

//
// Options - see the wrappers at the bottom of ShareModal for default if these are unset
//

interface Options {
  // what was clicked? This is used to show a transition.
  $source?: HTMLElement;

  // The title at the top of the modal
  prompt?: string;

  // email subject
  subject?: string;

  // tweet, email body, etc.
  text?: string;

  // link to share
  link?: string;

  // callback when closed
  onClose?: Callback;
}
type Callback = () => void;

//
// main entry point - look for .share elements, open ShareModal on click
//

export function initShareModal() {
  delegate('.share', 'click', e => {
    const $source = e.target as HTMLElement;
    const shareModal = new ShareModal({ $source });
    shareModal.show();
    track('Open Share Modal', { action: getPageAction() });
  });

  // this is a good place to change ios share icons to android share icons
  if (android) {
    document.querySelectorAll('.ios-share').forEach($btn => {
      $btn.classList.remove('ios-share');
      $btn.classList.add('android-share');
    });
  }
}

//
// async wrapper around ShareModal, resolves when closed
//

export function openShareModal(options: Options = {}) {
  return new Promise(resolve => {
    const opts = Object.assign(options, { onClose: resolve });
    const shareModal = new ShareModal(opts);
    shareModal.show();
  });
}

//
// ShareModal
//

export class ShareModal {
  private readonly options: Options;
  private readonly $modal: HTMLElement;

  constructor(options: Options) {
    this.options = options;
    this.$modal = document.querySelector('#share-modal') as HTMLElement;

    // init clipboard
    this.initClipboard();

    // buttons
    this.$emailButton.addEventListener('click', () => this.onClickEmail());
    this.$facebookButton.addEventListener('click', () => this.onClickFacebook());
    this.$twitterButton.addEventListener('click', () => this.onClickTwitter());
  }

  //
  // show the modal (or use navigator.share)
  //

  public show() {
    // On Android, Edge or Safari we use navigator.share (the device way of
    // sharing). Elsewhere we show the modal. Easiest to test with Safari.
    if ((window.navigator as any).share) {
      this.navigatorShare();
      return;
    }

    //
    // Setup the modal elements.
    //

    this.$prompt.innerText = this.prompt;
    this.$shareInput.value = this.link;
    this.$emailButton.href = this.mailtoUrl;

    //
    // show MicroModal
    //

    MicroModal.show('share-modal', {
      disableFocus: true,
      onClose: () => {
        if (new URLSearchParams(location.search).has('invite')) {
          // This will remove the query from the URL so that going back to this
          // page in history won't pop the modal up again after they've closed
          // it.
          history.replaceState(history.state, '', location.pathname);
        }
        this.onClose?.();
      },
    });

    //
    // If this was opened from an element, animate from that
    //

    if (this.$source) {
      this.$modal.classList.add('delayed-fade-in');
      transition(this.$source, this.$modal.querySelector('.modal-content') as HTMLElement);
    } else {
      // Otherwise use the micromodal default
      this.$modal.classList.remove('delayed-fade-in');
    }
  }

  //
  // navigator.share
  //

  private async navigatorShare() {
    // Android will reject when they cancel the share, so we have to catch.
    try {
      // not sure why TS doesn't know about navigator.share
      const navigator = window.navigator as any;
      await navigator.share({ title: document.title, url: this.link, text: this.text });
    } catch (e) {
      console.log('Caught sharing error', e);
    }
    this.options.onClose?.();
    return;
  }

  //
  // helpers
  //

  private popup(url: string, name: string, width: number, height: number) {
    const left = window.screenLeft + (window.innerWidth - width) / 2;
    const top = window.screenTop + (window.innerHeight - height) / 3;

    const specs =
      'copyhistory=no,directories=no,location=no,menubar=no,resizable=no,scrollbars=no,status=no,toolbar=no';
    const popup = window.open(
      url,
      name,
      `${specs},width=${width},height=${height},top=${top},left=${left}`,
    );
    popup?.focus();
  }

  private initClipboard() {
    const clipboard = new Clipboard('.copy-target', {
      // https://github.com/zenorocha/clipboard.js/issues/554
      container: this.$modal,
      text: () => this.link,
    });
    clipboard.on('success', () => this.onClipboardSuccess());
  }

  private get mailtoUrl() {
    const body = (this.text ? `${this.text}:\n\n` : '') + this.link;
    return mailto(this.subject, body);
  }

  private trackShare(platform: string) {
    track('Share', { url: this.link, platform });
  }

  //
  // events
  //

  private onClickEmail() {
    this.trackShare('email');
  }

  private onClickTwitter() {
    const url = Routes.url('https://twitter.com/intent/tweet', {
      text: this.text,
      url: this.link,
      via: 'FreshChalk',
    });
    this.popup(url, 'Tweet', 550, 400);
    this.trackShare('twitter');
  }

  private onClickFacebook() {
    const url = Routes.url('https://www.facebook.com/dialog/feed', {
      app_id: '204234956835157',
      display: 'popup',
      link: this.link,
    });
    this.popup(url, 'Share', 550, 400);
    this.trackShare('facebook');
  }

  private onClipboardSuccess() {
    showClipboardIndicator();
    this.trackShare('copy');
  }

  //
  // options & defaults
  //

  private get $source(): HTMLElement | undefined {
    return this.options.$source;
  }

  private get prompt(): string {
    return this.options.prompt || this.$modal.dataset.sharePrompt || 'Share this page';
  }

  private get subject(): string {
    return this.options.subject || this.$modal.dataset.shareSubject || 'Fresh Chalk';
  }

  private get text(): string | undefined {
    return this.options.text || this.$modal.dataset.shareText;
  }

  private get link(): string {
    return this.options.link || this.$modal.dataset.shareLink || window.location.href.split('#')[0];
  }

  private get onClose(): Callback | undefined {
    return this.options.onClose;
  }

  //
  // elements
  //

  private get $prompt() {
    return this.$modal.querySelector('.modal-title') as HTMLElement;
  }

  private get $shareInput() {
    return this.$modal.querySelector('.share-url') as HTMLInputElement;
  }

  private get $emailButton() {
    return this.$modal.querySelector('.btn.email') as HTMLAnchorElement;
  }

  private get $facebookButton() {
    return this.$modal.querySelector('.btn.facebook') as HTMLButtonElement;
  }

  private get $twitterButton() {
    return this.$modal.querySelector('.btn.twitter') as HTMLButtonElement;
  }

  private get $googleButton() {
    return this.$modal.querySelector('.btn.google') as HTMLButtonElement;
  }
}
