import tippy, { Instance } from 'tippy.js';
import { get } from './rest';
import Routes, { ApiBellsResponse } from './routes';
import { track } from './track';

export default function initPeekNotifications() {
  const $bell = document.querySelector('.navbar-bells');
  if (!$bell) {
    return;
  }
  $bell.addEventListener('click', (ev: MouseEvent) => {
    // If this isn't a left click, let the user still use this element
    // as a regular link
    if (ev.which !== 1) {
      return;
    }
    ev.preventDefault();
    showNotifications();
  });
}

function showNotifications() {
  const $bell = document.querySelector('.navbar-bells') as HTMLAnchorElement;
  // If tippy has already been initialized, stop here
  if (($bell as any)._tippy) {
    return;
  }

  const $wrapper = document.createElement('div');
  $wrapper.id = 'peek-bells';
  $wrapper.className = 'loading bells';

  const instance = tippy($bell, {
    animation: 'shift-away',
    appendTo: () => document.body,
    content: $wrapper,
    arrow: true,
    trigger: 'click',
    interactive: true,
    theme: 'light-border',
  }) as Instance;
  instance.show();

  loadNotifications($wrapper, $bell.href);

  track('View User Notifications Peek');
}

async function loadNotifications($wrapper: HTMLDivElement, href: string) {
  const json = await get<ApiBellsResponse>(Routes.ajaxBells());
  $wrapper.classList.remove('loading');
  if (!json.bells.length) {
    return;
  }
  const count = document.querySelector('.navbar-bells .navbar-badge-count');
  count?.remove();
  const $body = document.createElement('div');
  $body.className = 'body';
  for (const bell of json.bells) {
    const $bell = document.createElement('div');
    $bell.className = 'bell-wrapper';
    $bell.innerHTML = bell;
    $body.appendChild($bell);
  }
  $wrapper.appendChild($body);
  const $more = document.createElement('a');
  $more.className = 'view-more';
  $more.href = href;
  $more.textContent = 'click to view more';
  $more.addEventListener('click', () => track('Click View All User Notifications'));
  $wrapper.appendChild($more);
}
