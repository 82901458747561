export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
export const android = /Android.*Mobile/.test(navigator.userAgent);
export const mobile = iOS || android;

export const platform = (): string => {
  if (android) {
    return 'android';
  }
  if (iOS) {
    return 'ios';
  }
  return 'web';
};
