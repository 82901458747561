var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (from, karma, location, nameBolded, tag_name) {
      pug_html = pug_html + "\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name\"\u003E" + (null == (pug_interp = nameBolded) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"karma\"\u003E" + (pug.escape(null == (pug_interp = karma) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"l2\"\u003E\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = tag_name) ? "" : pug_interp)) + "\n&bull;\n\u003Cspan class=\"location\"\u003E" + (pug.escape(null == (pug_interp = location) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
if (from) {
pug_html = pug_html + "\n&bull;\n\u003Cspan class=\"from\"\u003Efrom " + (pug.escape(null == (pug_interp = from) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "from" in locals_for_with ?
        locals_for_with.from :
        typeof from !== 'undefined' ? from : undefined, "karma" in locals_for_with ?
        locals_for_with.karma :
        typeof karma !== 'undefined' ? karma : undefined, "location" in locals_for_with ?
        locals_for_with.location :
        typeof location !== 'undefined' ? location : undefined, "nameBolded" in locals_for_with ?
        locals_for_with.nameBolded :
        typeof nameBolded !== 'undefined' ? nameBolded : undefined, "tag_name" in locals_for_with ?
        locals_for_with.tag_name :
        typeof tag_name !== 'undefined' ? tag_name : undefined));
    ;;return pug_html;};
module.exports = template;