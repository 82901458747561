import MicroModal from 'micromodal';

/**
 * This will highlight content on the page based on any hash in the URL. For
 * example: `#review-1`
 */
export default function highlightHash() {
  const hash = window.location.hash;
  if (!hash) {
    return;
  }

  if (hash === '#recommend') {
    MicroModal.show('recommend-modal', {
      awaitCloseAnimation: true,
      disableFocus: true,
    });
    return;
  }
  const match = /(caution|review)-(\d+)/.exec(hash.substr(1));
  if (!match) {
    return;
  }

  const $el = document.querySelector(`.${match[0]}`);
  if (!$el) {
    return;
  }

  // Pull the highlighted node to the top of its list so that it's the first to
  // see.
  const $parent = $el.parentNode!;
  $parent.insertBefore($el, $parent.firstChild);
  $el.classList.add('sheet-highlight');
}
