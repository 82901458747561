import delegate from 'delegate';

export default function init() {
  delegate('.filter', 'click', (e: MouseEvent) => {
    const $filter = e.target as HTMLElement;
    if ($filter.classList.contains('active')) {
      return;
    }
    const $section = $filter.closest('section') as HTMLElement;
    $section.querySelectorAll('.active').forEach($x => $x.classList.remove('active'));
    $filter.classList.add('active');
    const { group } = $filter.dataset;
    if (!group) {
      return;
    }
    const $body = $section.querySelector(`.filter-body[data-group="${group}"]`);
    $body?.classList.add('active');
  });

  delegate('.show-more-btn', 'click', (e: MouseEvent) => {
    const $btn = e.target as HTMLElement;
    $btn.parentElement?.classList.add('expanded');
  });
}
