import countUp from './countUp';
import { fcMetadata } from './fc_metadata';
import initHamburger from './hamburger';
import { search } from './search';
import { mobile } from './userAgent';

export default function init() {
  initHamburger();

  // setup search
  const $navbar = document.querySelector('.navbar') as HTMLElement;
  const $search = $navbar.querySelector('input.search') as HTMLInputElement;

  // Logged in users get an "add recommendation" footer in navbar search.
  // Can't show this for logged out users because the modal requires a user.
  if (fcMetadata.logged_in) {
    search($search, {
      context: 'loggedin_navbar',

      // Leave space for footer on mobile
      limit: mobile ? 4 : undefined,
    });
  } else {
    if ($search) search($search);
  }

  if ($search) {
    $search.addEventListener('focus', e => {
      $navbar.classList.add('search-focussed-once');
    });
  }

  // Karma is only visible on desktop, so check first
  const $karmaToast = document.querySelector('#karma-toast') as HTMLElement;
  if ($karmaToast) {
    const $karmaCount = document.querySelector('.karma-count') as HTMLElement;
    countUp($karmaCount, 2000);
  }
}
