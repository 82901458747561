// amd: extracted from https://github.com/darrylhodgins/typescript-memoize

export function Memoize(this: any): MethodDecorator {
  return (_target: any, key: PropertyKey, descriptor: PropertyDescriptor): void => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const oldMethod = descriptor.get;
    if (!oldMethod) {
      throw new Error('Only put a Memoize() decorator on a get accessor.');
    }

    descriptor.get = function (...args) {
      const value = oldMethod.apply(this, args);
      Object.defineProperty(this, key, {
        configurable: false,
        enumerable: descriptor.enumerable,
        value,
      });
      return value;
    };
  };
}
