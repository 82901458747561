import csrfFetch from '../lib/csrfFetch';

export function get<T = any>(url: string) {
  return request<T>(url, 'GET');
}

export function post<T = any>(url: string, body?: any) {
  return request<T>(url, 'POST', body);
}

export async function request<T = any>(url: string, method: string, body?: any): Promise<T> {
  // get ready
  const requestInit: RequestInit = {
    body: body ? JSON.stringify(body) : null,
    headers: { 'Content-Type': 'application/json' },
    method,
  };

  // go!
  const response = await csrfFetch(url, requestInit);
  if (response.status !== 200) {
    console.error(response);
    throw new Error(`${url} - ${response.status} ${response.body}`);
  }

  // parse JSON
  return await response.json();
}
