import boldMatch from '../boldMatch';
import { CacheMap } from '../cacheMap';
import csrfFetch from '../csrfFetch';
import Routes from '../routes';
import { capitalize, squish } from '../util';
import suggestionRender from './suggestion.pug';

interface Suggestion {
  name: string;
  screen_name: string;
  image: string;

  display: string;
}

export default function initMention() {
  const mentionables = document.querySelectorAll('.mentionable');
  if (!mentionables.length) {
    return;
  }

  function processQuery(q: string) {
    return squish(q).toLowerCase();
  }

  const cache = new CacheMap<string, Suggestion[]>(processQuery);

  async function remoteSearch(q: string, callback: (suggestions: Suggestion[]) => void) {
    const cached = cache.get(q);
    if (cached) {
      return callback(cached);
    }

    // essay param
    let essay;
    const $form = document.activeElement?.closest("form");
    for (const i of ['conversation', 'review']) {
      const $input = $form?.querySelector(`#object_${i}_id`) as HTMLInputElement;
      if ($input) {
        essay = `${capitalize(i)}/${$input.value}`
        break;
      }
    }

    const response = await csrfFetch(Routes.searchUsers(q, essay));
    if (response.status !== 200) {
      console.error(response);
      throw new Error(`${response.url} failed - ${response.status}`);
    }

    // parse/sort
    const suggestions = (await response.json()) as Suggestion[];
    suggestions.sort((a, b) => a.name.localeCompare(b.name));

    // bold
    for (const suggestion of suggestions) {
      const display = boldMatch(q, suggestion.name);
      suggestion.display = suggestionRender({ ...suggestion, display });
    }

    cache.set(q, suggestions);
    callback(suggestions);
  }

  import('tributejs').then(mod => {
    const tribute = new mod.default({
      // https://github.com/zurb/tribute/issues/606
      // allowSpaces: true,
      fillAttr: 'screen_name',
      lookup: (i: Suggestion) => [i.name, i.screen_name].join(' '),
      menuItemTemplate(i) {
        const suggestion = i.original as Suggestion;
        return suggestion.display;
      },
      requireLeadingSpace: true,
      values: remoteSearch,
    });

    // It works with nodelist, but the typings don't know that.
    tribute.attach(mentionables as any);
  });
}
