import loadGoogleMapsApi from 'load-google-maps-api';
import * as Env from './env';
import { Memoize } from './memoize';
import { get } from './rest';
import Routes from './routes';
import { track } from './track';

//
// Google Map class
//

export default class Map {
  private static GMAP_OPTIONS = {
    clickableIcons: false,
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
  };

  public readonly $gmap: HTMLElement;
  public gmap?: google.maps.Map;
  public readonly params: MapParams;
  private readonly loaded?: Callback;
  private markers: Marker[];

  constructor($gmap: HTMLElement, params: MapParams, loaded?: Callback) {
    this.$gmap = $gmap;
    this.params = params;
    this.loaded = loaded;
    this.load();
  }

  // track an event, with our map params
  public track(event: string, properties?: Dict) {
    track(event, { ...this.params, ...properties });
  }

  private async load() {
    //
    // load Google Maps JS & /ajax/map in parallel.
    //

    let values: [any, MapResponse];
    try {
      values = await Promise.all([
        loadGoogleMapsApi({ key: Env.googleMapsApiKey }),
        get<MapResponse>(Routes.ajaxMap(this.params)),
      ]);
    } catch (error) {
      console.error('load error', error);
      return;
    }

    //
    // did we get anything?
    //

    const response = values[1];
    if (response.businesses.length === 0) {
      return;
    }

    //
    // now fire up google maps and add markers
    //

    this.gmap = new google.maps.Map(this.$gmap, Map.GMAP_OPTIONS);
    this.markers = response.businesses.map((json, index) => new Marker(this, json, index));

    //
    // set bounds
    //

    if (this.markers.length > 1) {
      this.gmap.fitBounds(this.bounds);
    } else {
      this.gmap.setCenter(this.markers[0].position);
      this.gmap.setZoom(15);
    }

    //
    // tracking
    //

    this.initTracking();

    //
    // done! fire loaded callback
    //

    if (this.loaded) {
      this.loaded();
    }
  }

  // markers call this when hovered
  set hover(value: Marker | null) {
    if (value) {
      this.$gmapHover.textContent = value.json.name;
    }
    this.$gmapHover.classList.toggle('show', value !== null);
  }

  private initTracking() {
    //
    // this series of event listeners is trying to notice when the user is
    // interacting with the map. We have to be careful because some of these
    // events fire when the page loads, or when the window resizes.
    //

    let hasBeenIdle = false;
    let tracked = false;

    const mapMove = () => {
      // ignore events until the map has fully loaded
      if (!hasBeenIdle) {
        return;
      }

      // only track once
      if (tracked) {
        return;
      }
      tracked = true;

      this.track('Map Move');
    };

    this.gmap!.addListener('dragend', mapMove);
    this.gmap!.addListener('zoom_changed', mapMove);
    this.gmap!.addListener('idle', () => (hasBeenIdle = true));
  }

  //
  // helpers
  //

  @Memoize() private get $gmapHover() {
    return document.querySelector('.gmap-hover') as HTMLElement;
  }

  private get bounds() {
    const bounds = new google.maps.LatLngBounds();
    this.markers.forEach(b => bounds.extend(b.position));
    return bounds;
  }
}

//
// marker (wraps a business)
//

class Marker {
  public readonly map: Map;
  public readonly json: MapJson;
  public readonly index: number;

  private readonly ICON = {
    path: google.maps.SymbolPath.CIRCLE,
    fillColor: Env.fcColors.blue,
    strokeColor: 'white',
    strokeOpacity: 1,
    strokeWeight: 1,
  };
  private readonly GREAT_ICON = { ...this.ICON, scale: 10, fillOpacity: 1 };
  private readonly OK_ICON = { ...this.ICON, scale: 6, fillOpacity: 0.8 };

  constructor(m: Map, json: MapJson, index: number) {
    this.map = m;
    this.json = json;
    this.index = index;

    // events
    this.marker.addListener('click', () => this.onClick());
    this.marker.addListener('mouseover', () => this.onMouseOver());
    this.marker.addListener('mouseout', () => this.onMouseOut());
  }

  // /ajax/map returns in karma order, so best businesses have lower indexes (and should be on top)
  get zIndex() {
    return 999 - this.index;
  }

  @Memoize() public get position() {
    return new google.maps.LatLng(this.json.lat, this.json.lon);
  }

  @Memoize() public get marker() {
    return new google.maps.Marker({
      map: this.map.gmap,
      icon: this.json.karma >= 1 ? this.GREAT_ICON : this.OK_ICON,
      position: this.position,
      zIndex: this.zIndex,
    });
  }

  //
  // events
  //

  private onClick() {
    this.map.track('Map Click', { business: this.json.key });
    window.open(`/${this.json.key}`, '_blank');
  }

  private onMouseOver() {
    this.map.hover = this;
  }

  private onMouseOut() {
    this.map.hover = null;
  }
}

//
// internal types
//

type Callback = () => void;

// tslint:disable:interface-over-type-literal
type MapParams = {
  tag: string;
  city: string;
  hood?: string;
};

interface MapResponse {
  businesses: MapJson[];
}

// like Business, but with some mandatory fields
interface MapJson {
  key: string;
  name: string;
  lat: number;
  lon: number;
  karma: number;
}
