import Vue from 'vue';

export function mountVueComponent<T extends Vue>(
  selector: string | Element,
  component: { new (): T }
): T {
  let $container: Element | undefined;
  if (typeof selector === 'string') {
    $container = document.querySelector(selector) as Element | undefined;
  } else {
    $container = selector;
  }
  if (!$container) {
    throw new Error(`Couldn't find mount selector.`);
  }

  const $el = $container.appendChild(document.createElement('div'));
  const app = new component();
  app.$mount($el);

  return app;
}
