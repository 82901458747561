import tippy, { hideAll } from 'tippy.js';
import { mobile } from '../lib/userAgent';
import { get } from './rest';
import Routes from './routes';
import { track } from './track';

const peekCache: { [key: string]: string } = {};

export interface PeekUserResponse {
  html: string;
}

export default function initPeek() {
  // no mobile support for now
  if (mobile) {
    return;
  }

  tippy('.peek-user-enabled', {
    appendTo: () => document.body,
    delay: [750, 0],
    content: '',
    allowHTML: true,
    arrow: false,
    interactive: true,
    interactiveDebounce: 100,
    placement: 'left',
    theme: 'light-border',
    onCreate(instance) {
      // setup initial state
      instance['_isFetching'] = false;
      instance['_error'] = null;
    },
    onShow(instance) {
      // hide all other tippys instantly, except this one
      hideAll({ exclude: instance, duration: 0 });

      // check if pending or previous error
      if (instance['_isFetching'] || instance['_error']) {
        return;
      }
      instance['_isFetching'] = true;

      const $ref = instance.reference as HTMLElement;
      const screenName = $ref.dataset.screenName;
      if (!screenName) {
        return;
      }

      peekFor(screenName)
        .then(response => {
          track('Peek User', { screen_name: screenName });
          instance.setContent(response);
        })
        .catch(error => {
          instance['_error'] = error;
        })
        .finally(() => {
          instance['_isFetching'] = false;
        });
    },
    onHidden(instance) {
      // unset properties so new network requests can be initiated
      instance['_error'] = null;
    },
  });
}

// Fetch remotely or hit cache to get peek for a user
async function peekFor(screenName: string) {
  if (!peekCache[screenName]) {
    const response = await get<PeekUserResponse>(Routes.ajaxPeekUser(screenName));
    if (response) {
      peekCache[screenName] = response.html;
    }
  }

  return peekCache[screenName];
}
