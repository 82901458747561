import {
  ApiBusiness,
  ApiHood,
  ApiProduct,
  ApiReview,
  ApiTag,
  ApiUser,
  ApiUserInterest
} from './api';
import { Suggestion } from './search';

//
// specific route responses
//

export interface ApiBellsResponse {
  bells: string[];
}

export interface ApiGameInitResponse {
  tags: ApiTag[];
  karma: number;
}

export interface ApiGameTagsResponse {
  tags: ApiTag[];
}

export interface ApiGameSuggestedTagsResponse {
  tags: ApiTag[];
}

export interface ApiGameAddBusinessReviewResponse {
  business: ApiBusiness;
  // Review will be null if it failed to create a new review for the business --
  // for example, if they already have a review.
  review: ApiReview | null;
  karma: number;
  was_new_review: boolean;
}

export interface ApiGameAddProductReviewResponse {
  product: ApiProduct;
  // Review will be null if it failed to create a new review for the business --
  // for example, if they already have a review.
  review: ApiReview | null;
  karma: number;
  was_new_review: boolean;
}

export interface ApiGameAddNotesResponse {
  karma: number;
}

export interface ApiGameAddInterestResponse {
  user_interest: ApiUserInterest;
}

export interface ApiOnboardSendInvitationsResponse {
  success: boolean;
}

export interface ApiOnboardWhoToFollowResponse {
  users: ApiUser[];
}

export interface ApiOnboardFollowUsersResponse {
  success: boolean;
}

export interface ApiOnboardSendInvitationsResponse {
  success: boolean;
}

export interface ApiUsersHoodsForZipResponse {
  hoods: ApiHood[];
}

export interface ApiUsersWhoToFollowNextResponse {
  html: string;
}

export interface ApiOnboardGetUserStatsResponse {
  following: number;
  review_count: number;
}

/** Just an array of tag names */
export type ApiTagsResponse = string[];

export default class Routes {
  //
  // specific routes
  //

  public static root() {
    return '/';
  }

  //
  // photos
  //

  public static signedPhotoUrl() {
    return Routes.url('/ajax/signed_photo_url');
  }

  public static addBusinessPhotos(businessKey: string) {
    return `/x/${businessKey}/add_photos`;
  }

  //
  // reviews
  //

  public static editReview(id: number) {
    return `/reviews/${id}/edit`;
  }

  //
  // game
  //

  public static game() {
    return '/game';
  }

  public static gameInit() {
    return '/game/init';
  }

  public static gameTags(offset: number) {
    return Routes.url('/game/tags', { offset });
  }

  /**
   * @param tag Should be the tag key
   */
  public static gameSearch(tag: string) {
    return Routes.url('/game/search', { tag });
  }

  public static gameSuggestedTags() {
    return Routes.url('/game/suggested_tags');
  }

  public static gameAddBusinessReview(suggestion: Suggestion) {
    return Routes.url('/game/add_business_review', { suggestion });
  }

  public static gameAddProductReview(suggestion: Suggestion) {
    return Routes.url('/game/add_product_review', { suggestion });
  }

  public static gameAddNotes(reviewId: number, notes: string, photos?: Atomic[], name?: string) {
    return Routes.url('/game/add_notes', { review_id: reviewId, notes, photos, name });
  }

  //
  // browse
  //

  public static cities() {
    return Routes.url('/cities');
  }

  public static newBusiness() {
    return Routes.url('/b/new');
  }

  //
  // onboarding
  //

  public static onboardConnect() {
    return Routes.url('/welcome/connect');
  }

  public static onboardWhoToFollow() {
    return Routes.url('/welcome/who_to_follow');
  }

  public static onboardSetBuilding(building: 'apt' | 'house') {
    return Routes.url('/welcome/set_building', { building });
  }

  public static onboardGetUserStats() {
    return Routes.url('/welcome/user_stats');
  }

  /**
   * Must send POST data with users (ids).
   */
  public static onboardFollowUsers() {
    return Routes.url('/welcome/follow_users');
  }

  /**
   * Must send POST data with contacts.
   */
  public static onboardSendInvitations() {
    return Routes.url('/welcome/send_invitations');
  }

  //
  // users
  //

  public static usersCreateAccount() {
    return Routes.url('/users/create-account');
  }

  public static usersHoodsForZip(zip: string) {
    return Routes.url('/users/hoods_for_zip', { zip });
  }

  public static usersWhoToFollowNext(blacklist: number[], skip: string, template?: string) {
    return Routes.url('/users/who_to_follow_next', {
      blacklist,
      skip,
      template: template || null,
    });
  }

  public static usersInvite() {
    return Routes.url('/users/invite');
  }

  public static usersMe(add?: boolean) {
    return Routes.url('/users/me', { add });
  }

  //
  // search
  //

  public static searchUsers(q?: string, essay?: string) {
    return Routes.url('/search_users', { q, essay });
  }

  public static searchBusinesses(tag: string, q = '') {
    return Routes.url('/search_businesses', { tag, q });
  }

  public static searchProducts(tag: string, q = '') {
    return Routes.url('/search_products', { tag, q });
  }

  //
  // ajax
  //

  public static ajaxBells() {
    return Routes.url('/ajax/bells');
  }

  public static ajaxBusinessTags() {
    return Routes.url('/ajax/business_tags');
  }

  public static ajaxProductTags() {
    return Routes.url('/ajax/product_tags');
  }

  public static ajaxMap(params: Dict) {
    return Routes.url('/ajax/map', params);
  }

  public static ajaxAddInterest(tagId: number) {
    return Routes.url('/ajax/add_interest', { tag_id: tagId });
  }

  public static ajaxRemoveInterest(tagId: number) {
    return Routes.url('/ajax/remove_interest', { tag_id: tagId });
  }

  public static ajaxRemovePhotos() {
    return Routes.url('/ajax/remove_photos');
  }

  public static ajaxPeekUser(screenName: string) {
    return Routes.url(`/${screenName}/peek`);
  }

  //
  // helpers
  //

  /**
   * Helper for building URLs.
   */
  public static url(url: string, params: Dict = {}) {
    // parse url into path?query
    let path = url;
    let query = '';
    const sep = url.indexOf('?');
    if (sep !== -1) {
      path = url.substring(0, sep);
      query = url.substring(sep + 1);
    }

    // strip out anchor if present
    let anchor = path.indexOf('#');
    if (anchor !== -1) {
      path = path.substring(0, anchor);
    }
    anchor = query.indexOf('#');
    if (anchor !== -1) {
      query = query.substring(0, anchor);
    }

    // update query
    const sp = new URLSearchParams(query);
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined || value === null || value === '') {
        sp.delete(key);
      } else if (Array.isArray(value)) {
        for (const v2 of value) {
          if (v2 === undefined || v2 === null || v2 === '') {
            sp.append(`${key}[]`, '');
          } else if (typeof v2 !== 'object') {
            sp.append(`${key}[]`, v2.toString());
          } else {
            this.encodeQueryObject(sp, `${key}[]`, v2);
          }
        }
      } else if (typeof value !== 'object') {
        sp.set(key, value.toString());
      } else {
        this.encodeQueryObject(sp, key, value);
      }
    }
    query = sp.toString();

    // return
    return query.length === 0 ? path : `${path}?${query}`;
  }

  /**
   * Encode an object into a format that Rails can convert into a hash.
   */
  private static encodeQueryObject(
    sp: URLSearchParams,
    keyPrefix: string,
    obj: Record<string, any>,
  ) {
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        sp.append(`${keyPrefix}[${key}]`, value.toString());
      }
    }
  }
}

/**
 * Helper for building mailto URLs.
 */
export function mailto(subject: string, body: string, to?: string) {
  let url = Routes.url(`mailto:${to || ''}`, { subject, body });
  // Mail.app on MacOS/iOS doesn't allow '+'
  url = url.replace(/\+/g, '%20');
  return url;
}
