import { sortBy } from '../util';

interface SearchEntry {
  value: string;
  key: string;
}

export default class AutocompleteQuery {
  private readonly entries: SearchEntry[];
  private readonly limit: number;

  constructor(items: string[], limit: number | null = 10) {
    this.entries = items.sort().map(s => this.entryForString(s));
    this.limit = limit || 0;
  }

  public search(query: string): string[] {
    const re = this.reForQuery(query);
    let results = this.entries.filter(i => re.test(i.key));

    // Sort startsWith matches to the front
    const reStartsWith = new RegExp(`^${re.source}`);
    results = sortBy(results, i => (reStartsWith.test(i.key) ? 0 : 1));

    if (this.limit) {
      results = results.slice(0, this.limit);
    }

    return results.map(i => i.value);
  }

  protected reForQuery(q: string): RegExp {
    let s = this.searchKey(q);
    if (!s) {
      // empty? don't match anything
      return /XYZZY/;
    }
    s = s.replace(/ /g, ' ?');
    s = `\\b${s}`;
    return new RegExp(s);
  }

  protected entryForString(s: string): SearchEntry {
    return { value: s, key: this.searchKey(s) };
  }

  protected searchKey(s: string): string {
    return s
      .toLowerCase()
      .replace(/[^\w\d ]/g, '')
      .replace(/\s+/, ' ');
  }
}
