// @ts-ignore
import AppPhotoGalleryType from './components/photoGallery.ts';
import AppPhotoGallery from './components/photoGallery.vue';
import transition, { transitionCss } from './transition';
import { mountVueComponent } from './vue';

/**
 * The first time we initialize the rec modal, we store it here and leave it in
 * the DOM (just hide/show it).
 */
let photoGallery: AppPhotoGalleryType | null = null;

export function initPhotoGallery() {
  document.querySelectorAll('[data-gallery-photos]').forEach(($i: HTMLElement) => {
    $i.addEventListener('click', (e: MouseEvent) => {
      const $target = e.target as HTMLElement;
      const $btn = $target.closest('.btn') as HTMLElement | null;
      if ($btn && !$btn.dataset.galleryLink) {
        return;
      }
      const { galleryName } = $i.dataset;
      const photos = JSON.parse($i.dataset.galleryPhotos!);
      openPhotoGallery({ $from: $i, galleryName, photos })
    });
  });
}

interface PhotoGalleryOptions {
  $from?: HTMLElement;
  galleryName?: string;
  photos?: any;
}

export async function openPhotoGallery(options: PhotoGalleryOptions = {}) {
  if (!options.photos?.length) {
    return;
  }
  // If we haven't created the global gallery modal yet, let's create/mount it. From
  // then on we can just show/hide it.
  if (!photoGallery) {
    const $modal = document.createElement('div');
    $modal.className = 'mount-photo-gallery';
    document.body.insertBefore($modal, document.body.lastChild);
    photoGallery = mountVueComponent<AppPhotoGalleryType>('.mount-photo-gallery', AppPhotoGallery);
    photoGallery.$on('close', handlePhotoGalleryClose);
  }

  // default rec search term to contents of header search box
  photoGallery.show(options.photos, options.galleryName);

  // If $from was omitted, stop here
  const { $from } = options;
  if (!$from) {
    return;
  }

  $from.style.visibility = 'hidden';
  photoGallery.$from = $from;

  // Wait for the element to update its state
  await photoGallery.$nextTick();

  // Animate the modal from the $from element
  const $dialog = photoGallery.$refs.dialog;
  const $content = photoGallery.$refs.content;

  // Animate the modal from the $from element
  $dialog.classList.add('opening');
  transition($from, $dialog).then(() => $dialog.classList.remove('opening'));
  // Fade in modal contents after a delay
  const DURATION = 400; // ms
  const DELAY = DURATION * 0.75;
  transitionCss($content, {
    delay: DELAY,
    duration: DURATION - DELAY,
    from: {
      opacity: '0',
    },
  });
}

function handlePhotoGalleryClose() {
  if (!photoGallery) {
    return;
  }
  // If this isn't from a tag suggestion, do nothing
  const $from = photoGallery.$from as HTMLElement | null;
  photoGallery.$from = null;
  if (!$from) {
    return;
  }
  // Animate the modal back to the source
  const $dialog = photoGallery.$refs.dialog;
  // Initial state
  $dialog.style.opacity = '0'; // Hide the modal
  $from.style.visibility = ''; // Show the suggested tag
  $from.style.zIndex = '1021'; // Make sure it appears on top of other elements
  // Animate the suggested tag element from the modal element
  transition($dialog, $from).then(() => {
    // Reset inline styles
    $dialog.style.opacity = '';
    $from.style.zIndex = '';
  });
  // Fade in child elements after a delay
  const DURATION = 400; // ms
  const DELAY = DURATION * 0.75;
  transitionCss($from.children, {
    delay: DELAY,
    duration: DURATION - DELAY,
    from: {
      opacity: '0',
    },
  });
}
