import initReviews from './_reviews';

export default function action() {
  // highlight review from location.hash
  if (window.location.hash) {
    const reviewId = window.location.hash.substr(1);
    const $el = document.querySelector(`[data-id="${reviewId}"]`) as HTMLElement;
    if ($el) {
      $el.classList.add('highlight');
    }
  }
  initReviews();
}
