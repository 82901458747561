var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (location, nameBolded) {
      pug_html = pug_html + "\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name\"\u003E" + (null == (pug_interp = nameBolded) ? "" : pug_interp) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"l2\"\u003E\u003Cdiv\u003E\u003Cspan class=\"location\"\u003E" + (pug.escape(null == (pug_interp = location) ? "" : pug_interp)) + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "location" in locals_for_with ?
        locals_for_with.location :
        typeof location !== 'undefined' ? location : undefined, "nameBolded" in locals_for_with ?
        locals_for_with.nameBolded :
        typeof nameBolded !== 'undefined' ? nameBolded : undefined));
    ;;return pug_html;};
module.exports = template;