import MicroModal from 'micromodal';
import { hideAll } from 'tippy.js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { toggleHamburger } from '../hamburger';
import { track } from '../track';

interface Photo {
  full: string;
  thumbnail: string;
}

@Component
export default class AppPhotoGallery extends Vue {
  public isClosed = false;
  public name = '';
  public photos: Photo[] = [];
  public index = 0;

  public $refs: {
    modal: HTMLDivElement;
    dialog: HTMLDivElement;
    content: HTMLDivElement;
    sidebar: HTMLDivElement;
    thumbnails: HTMLDivElement[];
  };

  @Prop({ type: HTMLElement, default: null })
  public $from: HTMLElement | null;

  get fullImageSrc() {
    return this.photos[this.index]?.full;
  }

  get modalStatus() {
    return `${this.index + 1} of ${this.photos.length}`;
  }

  public mounted() {
    window.addEventListener('keydown', this.handleKey.bind(this));
  }

  public show(photos, name) {
    // If they opened the rec modal from the hamburger menu, make sure it's
    // closed.
    toggleHamburger(false);
    hideAll();

    this.clear();
    this.name = name;
    this.photos.push(...photos);

    MicroModal.show('photo-gallery-modal', {
      awaitCloseAnimation: true,
      disableFocus: true,
      disableScroll: true,
      onClose: () => this.onClose(),
    });

    track('Open Photo Gallery');
  }

  public close() {
    MicroModal.close('photo-gallery-modal');
  }

  public restart() {
    this.clear();
  }

  private clear() {
    // Clear everything out to start fresh.
    this.isClosed = false;
    this.name = '';
    this.photos.splice(0);
    this.index = 0;
  }

  private onClose() {
    // Emit a Vue event for this closing
    this.$emit('close');
    this.isClosed = true;
  }

  // Scrolls the active thumbnail into view
  private updateThumbnail() {
    const $thumbnail = this.$refs.thumbnails[this.index];
    const thumbnailRect = $thumbnail.getBoundingClientRect();
    const sidebarRect = this.$refs.sidebar.getBoundingClientRect();
    if (thumbnailRect.top < sidebarRect.top + sidebarRect.height / 2) {
      $thumbnail.style.transform = 'translateY(-4rem)';
    } else {
      $thumbnail.style.transform = 'translateY(1rem)';
    }
    $thumbnail.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    $thumbnail.style.transform = '';
  }

  setIndex(index: number, method: string) {
    this.index = index;
    this.updateThumbnail();
    track('Select Photo in Gallery', { method });
  }

  //
  // Events
  //

  private selectPhoto(photo: Photo) {
    this.setIndex(this.photos.indexOf(photo), 'thumbnail');
  }

  private handleArrow(direction: number, method = 'arrow') {
    let index = (this.index + direction) % this.photos.length;
    if (index < 0) {
      index = this.photos.length - 1;
    }
    this.setIndex(index, method);
  }

  private handleKey(event: KeyboardEvent) {
    if (this.isClosed) {
      return;
    }
    switch (event.key) {
      case 'ArrowLeft': this.handleArrow(-1, 'keyboard'); break;
      case 'ArrowRight': this.handleArrow(1, 'keyboard'); break;
      default: return;
    }
    event.preventDefault();
  }
}
