export default function action() {
  document.querySelectorAll('.autosize').forEach(i => initAutosize(i));
}

export async function initAutosize($el: Element) {
  // We do an async import since the page works just fine without this loaded in
  // immediately. Reduce the initial bundle size.
  const mod = await import('autosize');
  mod.default($el);
}
