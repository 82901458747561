import delegate from 'delegate';
import { initThreads } from '../lib/threads';
import initTimeAgo from '../lib/timeAgo';
import { track } from '../lib/track';

export default function action() {
  initThreads();

  // Need to update timestamps client-side since we serve cached content.
  initTimeAgo();

  // handle interest prompts
  delegate('.alert .btn', 'ajax:success', (e: CustomEvent) => {
    if (e.target) {
      const [data, _status, xhr]: [any, string, XMLHttpRequest] = e.detail;
      const $btn = e.target as HTMLElement;
      const $alert = $btn.closest('.alert');
      if ($alert) {
        const $feedItem = $alert.closest('.interest');
        const onAnimationEnd = _ => {
          if (xhr.status === 200 && data instanceof HTMLDocument) {
            $feedItem?.insertAdjacentHTML('afterend', xhr.response);
          }
          $feedItem?.remove();
        };
        $alert.addEventListener('webkitAnimationEnd', onAnimationEnd);
        $alert.addEventListener('animationend', onAnimationEnd);
        $alert.classList.remove('alert-primary');
        $alert.classList.add('alert-success', 'anim-very-delayed-fade-out');
        $alert.innerHTML =
          "<div class='message'>Thanks! This will help us give you better recommendations.</div>";
      }
    }
  });

  //
  // track scroll
  //

  let hasScrolled = false;
  let hasScrolledToBottom = false;

  window.addEventListener('scroll', () => {
    // Fire an event when the user has scrolled a meaningful amount.
    if (!hasScrolled && window.pageYOffset > 1000) {
      hasScrolled = true;

      const items = document.querySelectorAll('.feed-item').length;
      track('Scroll Feed', { items });
    }

    if (!hasScrolledToBottom) {
      const distToBottom =
        document.documentElement.scrollHeight - (window.pageYOffset + window.innerHeight);

      // Fire another event when they get down to the footer.
      if (distToBottom < 300) {
        hasScrolledToBottom = true;

        const items = document.querySelectorAll('.feed-item').length;
        track('Scroll Feed to Bottom', { items });
      }
    }
  });
}
