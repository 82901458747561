export default function action() {
  //
  // new group form
  //

  // Form can be nil outside of metros.
  const $form = document.querySelector('.new-group form') as HTMLFormElement;
  if ($form) {
    const $error = document.querySelector('.new-group .error') as HTMLElement;

    $form.addEventListener('ajax:before', e => {
      $error.classList.remove('show');
    });
    $form.addEventListener('ajax:error', (e: CustomEvent) => {
      console.log(e);
      const [data, _status, _xhr]: [any, string, XMLHttpRequest] = e.detail;
      $error.classList.add('show');
      $error.textContent = data.error_messages[0];
    });
    $form.addEventListener('ajax:success', e => {
      $form.classList.add('collapse');
      window.location.reload();
    });
  }
}
