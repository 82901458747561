import delegate from 'delegate';

//
// handle ajax like and like_clear
//

interface LikeResponseData {
  redirect?: string;
  like: boolean;
  likes_count: number;
  likes_count_humanized: string;
}

export default function action() {
  // delegate here since likable content is sometimes added to the page dynamically
  delegate('.like', 'ajax:success', onAjaxSuccess);
}

const onAjaxSuccess = (e: CustomEvent) => {
  const [data, _status, _xhr]: [LikeResponseData, string, XMLHttpRequest] = e.detail;

  // handle redirect (not logged in)
  if (data.redirect) {
    window.location.href = data.redirect;
    return;
  }

  // success!
  const $like = (e.target as HTMLElement).closest('.like') as HTMLElement;

  // toggle the heart
  const $heart = $like.querySelector('.like-heart') as HTMLElement;
  if ($heart) {
    $heart.classList.toggle('liked', data.like);
  }

  // update the count
  const $count = $like.querySelector('.like-count') as HTMLElement;
  if ($count) {
    $count.textContent = data.likes_count.toString();
    $count.dataset.count = data.likes_count.toString();
  }
};
