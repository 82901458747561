import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import AppBtn from '../../lib/components/btn.vue';

export interface Email {
  id?: number;
  width?: number;
  name?: string;
  address: string;
}

// A small form for sending invites to user-specified email addresses
@Component({
  components: {
    AppBtn,
  },
})
export default class AppCustomEmailInvite extends Vue {
  public $refs: {
    emails: HTMLDivElement;
    message: HTMLDivElement;
  };

  // The list of email addresses shown in the "To" section
  public to = '';
  // The current value of the message input
  public message = '';
  // The most recently emitted count of pending invites
  public previousPending = 0;

  // When specified, replaces the "To" input with a static message
  @Prop(String)
  public recipients: string;

  // When specified, the message input will be created with this value
  @Prop(String)
  public defaultMessage: string;

  // Returns whether the inviations are ready to send
  get isReady() {
    return this.emails.length;
  }

  // Gets the emails from the current input value
  get emails() {
    return this.to.split(/(?:[ ,;]|\n)+/gm).filter(x => /.+@.+\..+/.test(x));
  }

  // Converts the emails to the format expected by the API endpoint
  get contacts() {
    return this.emails.map(x => ({ email: x }));
  }

  public handleInput() {
    // Emit the number of pending invitations
    const pending = this.emails.length;
    if (this.previousPending !== pending) {
      this.previousPending = pending;
      this.$emit('count', pending);
    }
  }

  // Add a populated address to the end of the visible list
  addAddress(address: string, name = '') {
    // Add this email to the end
    if (this.to) {
      this.to += ', ' + address;
    } else {
      this.to = address;
    }
    // Update the count
    this.handleInput();
  }

  // Sets the addresses
  setTo(to: string) {
    this.to = to;
  }

  // Sets the message body
  setMessage(message: string) {
    this.message = message;
  }

  // Vue Events

  created() {
    this.message = this.defaultMessage || '';
  }

  // API Calls

  // Sends email invitations to all of the emails that were specified by the user
  sendInvites() {
    this.$emit('invite', this.contacts, this.message);
  }
}
