import delegate from 'delegate';
import { fcMetadata } from '../lib/fc_metadata';
import { track } from '../lib/track';

interface Metadata {
  clock: number;
  jar: string;
}

export default function action() {
  new Action();
}

class Action {
  private $clock: HTMLElement;
  private now = new Date().getTime();

  constructor() {
    // clock
    const metadata = fcMetadata.page as Metadata;
    if (metadata.clock !== undefined) {
      this.$clock = document.querySelector('.clock') as HTMLElement;
      setInterval(() => this.tick(), 500);
    }

    delegate("input[type='radio']", 'change', this.toggleSelected.bind(this));
    delegate("button[type='submit']", 'click', this.enterTokens.bind(this));

    // listen for $play ajax response
    document.querySelectorAll('.play').forEach($play => {
      if ($play) {
        $play.addEventListener('ajax:success', (e: CustomEvent) => {
          const [data, _status, _xhr]: [any, string, XMLHttpRequest] = e.detail;
          $play.innerHTML = data.html;
        });
      }
    });
  }

  private enterTokens(e: Event) {
    // Tracking. This has to be done manually because data-track only adds
    // listeners at DOM ready time.
    const metadata = fcMetadata.page as Metadata;
    const $el = e.target as HTMLElement;
    const tokens = parseInt($el.dataset.tokens as string, 10);

    if (tokens > 0) {
      track('Put In Tokens', { jar: metadata.jar, tokens });
    } else {
      track('Remove Tokens', { jar: metadata.jar });
    }
  }

  private toggleSelected(e: Event) {
    // clear selected class from all
    document.querySelectorAll('.prize').forEach($i => $i.classList.remove('selected'));

    // add selected to prize just clicked
    const $radio: HTMLInputElement = e.target as HTMLInputElement;
    const $prize: HTMLElement = $radio.closest('.prize') as HTMLElement;
    $prize.classList.add('selected');
  }

  // updates countdown
  private tick() {
    const clockMillis = this.clockMillis;
    if (clockMillis < 0) {
      const $parent = this.$clock.parentNode as HTMLElement;
      $parent.classList.add('soon');
      return;
    }

    // convert clockMillis => dhms => string
    const dhms = this.duration_to_dhms(clockMillis);
    const array: string[] = [];
    if (dhms.days > 0) {
      array.push(dhms.days.toString());
    }
    array.push(dhms.hours.toString().padStart(2, '0'));
    array.push(dhms.minutes.toString().padStart(2, '0'));
    array.push(dhms.seconds.toString().padStart(2, '0'));
    const text = array.join(':');

    // turn chars into spans
    const html = text
      .split('')
      .map(c => {
        const klass = c === ':' ? 'c' : '';
        return `<span class="${klass}">${c}</span>`;
      })
      .join('');
    this.$clock.innerHTML = html;
  }

  // converts duration in days/hours/minutes/seconds
  private duration_to_dhms(durationInMs: number) {
    const SECOND = 1000;
    const MINUTE = SECOND * 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;

    let ms = durationInMs;
    function decrement(unit: number) {
      const times = Math.floor(ms / unit);
      ms -= times * unit;
      return times;
    }
    return {
      days: decrement(DAY),
      hours: decrement(HOUR),
      minutes: decrement(MINUTE),
      seconds: decrement(SECOND),
    };
  }

  // how much time is left on the clock, in ms?
  get clockMillis() {
    const clockSeconds = (fcMetadata.page as Metadata).clock;
    const elapsedMillis = new Date().getTime() - this.now;
    return clockSeconds * 1000 - elapsedMillis;
  }
}
