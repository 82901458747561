import delegate from 'delegate';
import { hide, show } from '../lib/animations';

//
// set data-toggle='toggle' and data-target='selector' to toggle the .toggle
// class on and off for the target selector. Note that the selector tries
// closest, then falls back to document.querySelector.
//
// set data-toggle='show' to show/hide elements.
//

export default function init() {
  delegate('[data-toggle]', 'click', (e: Event) => {
    const $target = e.target as HTMLElement;
    // a descendant element can be the target, thus find closest [data-toggle]
    const $eventTarget = $target.closest('[data-toggle]') as HTMLElement;
    if ($eventTarget.dataset.toggle === 'toggle') {
      onToggleClick(e, $eventTarget);
    } else if ($eventTarget.dataset.toggle === 'show') {
      onCollapseClick(e, $eventTarget);
    } else {
      console.warn(`data-toggle, unknown value '${$eventTarget.dataset.toggle}'`);
    }
  });
}

function onToggleClick(e: Event, $eventTarget: HTMLElement) {
  const $target = targetFor($eventTarget);
  const toggled = $target.classList.toggle('toggle');
  const href = $eventTarget.getAttribute('href');
  // allow for links, if present (including anchor links)
  if (href === null || href === '#') {
    e.preventDefault();
  }

  // Fire a 'toggle' event
  const event = new CustomEvent('toggle', { detail: toggled });
  $target.dispatchEvent(event);
}

function onCollapseClick(e: Event, $eventTarget: HTMLElement) {
  const $target = targetFor($eventTarget);
  const fn = $target.classList.contains('show') ? show : hide;
  fn($target);
  e.preventDefault();
}

// lookup data-target
function targetFor($element: HTMLElement): HTMLElement {
  const selector = $element.dataset.target || '';

  // try closest
  let $target = $element.closest(selector);
  if (!$target) {
    $target = document.querySelector(selector);
    if (!$target) {
      console.error(`data-toggle, couldn't find '${selector}'`);
    }
  }
  return $target as HTMLElement;
}
