import Rails from '@rails/ujs';
import polyfillClosest from 'element-closest';
import 'whatwg-fetch';
import about_sites from './about/sites';
import businesses_dashboard from './businesses/dashboard';
import businesses_new from './businesses/new';
import businesses_show from './businesses/show';
import cautions_edit from './cautions/edit';
import cities_businesses from './cities/businesses';
import cities_index from './cities/index';
import cities_show from './cities/show';
import cities_tag from './cities/tag';
import conversations_show from './conversations/show';
import editor_businesses_new from './editor/new';
import feed_show from './feed/show';
import ghost_show from './ghost/show';
import guides_freshgiving_index from './guides/freshgivingIndex';
import guides_index from './guides/index';
import guides_show from './guides/show';
import kf_index from './kf/index';
import initAutosize from './lib/autosize';
import initClearBells from './lib/clearBells';
import initClipboard from './lib/clipboard';
import initDevelopment from './lib/development';
import initFloatLabel from './lib/floatLabel';
import initFollow from './lib/follow';
import { initPhotoGallery } from './lib/gallery';
import initHighlighter from './lib/highlighter';
import initHomeHero from './lib/homeHero';
import initHref from './lib/href';
import initImageErrors from './lib/imageErrors';
import initLike from './lib/like';
import initMention from './lib/mention/mention';
import initNavbar from './lib/navbar';
import initPeekNotifications from './lib/peekNotifications';
import initPeekUser from './lib/peekUser';
import initPersonalize from './lib/personalize';
import initRegisterPrompt from './lib/registerPrompt';
import { initShareModal } from './lib/share';
import initSnowfall from './lib/snowfall';
import initSticky from './lib/sticky';
import initToggle from './lib/toggle';
import { initTrack } from './lib/track';
import { platform } from './lib/userAgent';
import product_tags_index from './product_tags/index';
import products_show from './products/show';
import profiles_edit from './profiles/edit';
import profiles_find_friends from './profiles/find_friends';
import profiles_followers from './profiles/followers';
import profiles_following from './profiles/following';
import profiles_invite_hub from './profiles/invite_hub';
import profiles_network from './profiles/network';
import profiles_show from './profiles/show';
import reviews_edit from './reviews/edit';
import users_bells from './users/bells';
import users_create_account from './users/create_account';
import users_edit from './users/edit';
import users_magic_link from './users/magic_link';

//
// actions
//

type ActionCallback = () => void;
type ActionModuleLoad = () => Promise<{ default: ActionCallback }>;

const actions: { [key: string]: ActionCallback } = {
  about_sites,
  businesses_dashboard,
  businesses_invite_send: businesses_dashboard,
  businesses_new,
  businesses_show,
  cautions_edit,
  cautions_new: cautions_edit,
  cities_businesses,
  cities_index,
  cities_show,
  cities_tag,
  conversations_show,
  editor_businesses_new,
  feed_show,
  ghost_show,
  guides_freshgiving_index,
  guides_index,
  guides_show,
  kf_index,
  product_tags_index,
  products_show,
  profiles_invite_hub,
  profiles_invite_send: profiles_invite_hub,
  profiles_edit,
  profiles_find_friends,
  profiles_followers,
  profiles_following,
  profiles_network,
  profiles_show,
  reviews_edit,
  users_create_account,
  users_edit,
  users_magic_link,
  users_bells,
  users_update: users_edit,
};

// Use dynamic actions to load action code in an async-loaded chunk. There is a
// trade off of reducing the initial chunk size of the page vs having to load a
// separate file to bootstrap the page.
const dynamicActions: { [key: string]: ActionModuleLoad } = {
  businesses_badge_codes: () => import('./businesses/badgeCodesBootstrap'),
  game_index: () => import('./onboard/gameBootstrap'),
  onboard_connect: () => import('./onboard/connectBootstrap'),
  onboard_index: () => import('./onboard/welcomeBootstrap'),
  profiles_edit: () => import('./profiles/edit_dynamic'),
  editor_businesses_edit: () => import('./editor/edit_dynamic'),
  users_create_account: () => import('./users/create_account_dynamic'),
  users_edit: () => import('./users/edit_dynamic'),
  ghost_show: () => import('./ghost/show_dynamic'),
};

//
// onReady
//

const onReady = () => {
  // polyfills
  polyfillClosest(window);

  // Begin downloading of lazy modules as soon as we can. Because of the way JS
  // works, the modules will actually load after this function returns, since
  // it'll load up and then run in the next available event tick.
  lazyLoadModule(() => import('./lazy'));

  // decorate body with platform
  document.body.dataset.platform = platform();

  // global stuff supported on all pages
  initAutosize();
  initClipboard();
  initClearBells();
  initFloatLabel();
  initFollow();
  initHighlighter();
  initHomeHero();
  initHref();
  initImageErrors();
  initLike();
  initMention();
  initNavbar();
  initPeekNotifications();
  initPeekUser();
  initPersonalize();
  initPhotoGallery();
  initRegisterPrompt();
  initShareModal();
  initSnowfall();
  initSticky();
  initToggle();
  initTrack();

  // finally, run the action
  const $body = document.querySelector('body') as HTMLBodyElement;
  const actionName = $body.dataset.action!;

  const action = actions[actionName];
  if (action) {
    action();
  }

  const dynamicAction = dynamicActions[actionName];
  if (dynamicAction) {
    lazyLoadModule(dynamicAction);
  }

  if (document.body.dataset.env === 'development') {
    initDevelopment();
  }
};

/**
 * Loads the dynamic import through the loader function and calls its default
 * exported function.
 */
const lazyLoadModule = async (load: ActionModuleLoad) => {
  const mod = await load();
  mod.default();
};

document.addEventListener('DOMContentLoaded', onReady, false);

//
// Rails UJS
//

Rails.start();
