export const show = ($el: HTMLElement) => {
  $el.classList.add('collapsing');
  $el.classList.remove('collapse');
  $el.style.height = `${$el.scrollHeight}px`;

  // handler for when the transition is done
  const cb = (e: Event) => {
    $el.classList.remove('collapsing');
    $el.classList.add('show');
    $el.style.height = '';
    $el.removeEventListener(e.type, cb);
  };
  $el.addEventListener('transitionend', cb);
};

export const hide = ($el: HTMLElement) => {
  // manually set height
  $el.style.height = `${$el.scrollHeight}px`;
  $el.classList.add('collapsing');
  $el.classList.remove('show');

  // force reflow so height we just set is ready to go
  const ignore = $el.offsetWidth;

  // now transition
  $el.style.height = '0px';

  // handler for when the transition is done
  const cb = (e: Event) => {
    $el.classList.remove('collapsing');
    $el.classList.add('collapse');
    $el.style.height = '';
    $el.removeEventListener(e.type, cb);
  };
  $el.addEventListener('transitionend', cb);
};
