var pug = require("!../../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (count, name) {
      pug_html = pug_html + "\u003Cdiv class=\"l1\"\u003E\u003Cdiv class=\"name tag\"\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv class=\"count\"\u003E" + (pug.escape(null == (pug_interp = count) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "count" in locals_for_with ?
        locals_for_with.count :
        typeof count !== 'undefined' ? count : undefined, "name" in locals_for_with ?
        locals_for_with.name :
        typeof name !== 'undefined' ? name : undefined));
    ;;return pug_html;};
module.exports = template;